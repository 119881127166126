import { createStore, applyMiddleware, combineReducers, AnyAction } from 'redux'
import {
  RouterState,
  connectRouter,
  routerMiddleware,
} from 'connected-react-router'
import reduxSaga from 'redux-saga'
import * as effects from 'redux-saga/effects'
import { createBrowserHistory } from 'history'
import { composeWithDevTools } from 'redux-devtools-extension'
import * as locale from './Locale'
import * as info from './Info'
import * as login from './../../components/Authentication/Login'
import * as account from './../../components/Account'
import * as tfaRequest from './../../components/Authentication/TFAEmail'
import * as resetPassword from './../../components/Authentication/ResetPassword'
import { pipe } from 'fp-ts/lib/pipeable'
import { io, ioEither, console } from 'fp-ts'
import { getItem } from './io'
import * as localStorage from './LocalStorage'
import * as units from './Units'
import * as notifications from '../Notifications'

export type Actions =
  | locale.Actions
  | info.Actions
  | login.Actions
  | account.Actions
function* initSaga() {
  yield effects.all([
    effects.fork(info.infoInit),
    effects.put(login.getAccountAction()),
  ])
}
function* rootSaga() {
  yield effects.fork(initSaga)
  yield effects.all([
    localStorage.saga(),
    login.saga(),
    resetPassword.saga(),
    account.saga(),
    tfaRequest.saga(),
    units.saga(),
    locale.saga(),
  ])
}

const sagaMiddleware = reduxSaga()
export const history = createBrowserHistory({ basename: '/accounts' })

export type State =
  | {
      info: info.State
      account: login.State
      accountSetting: account.State
      tfaRequest: tfaRequest.State
      locale: locale.State
      resetPassword: resetPassword.State
      router: RouterState
      units: units.State
      notifications: notifications.State
    }
  | undefined

const loadState: io.IO<Partial<State>> = pipe(
  getItem('info', info.StateC),
  ioEither.map((info) => ({ info })),
  io.chain((pe) =>
    pipe(
      getItem('organization', account.StateC),
      ioEither.map((accountSetting) => ({
        ...pe,
        accountSetting: { ...account.initialState, ...accountSetting },
      }))
    )
  ),
  ioEither.getOrElse((l) =>
    pipe(
      console.warn('bad state'),
      io.chain(() => console.warn(l)),
      io.map((): Partial<State> => ({}))
    )
  )
)
const appReducer = combineReducers<State | undefined>({
  info: info.reducer,
  account: login.reducer,
  accountSetting: account.reducer,
  tfaRequest: tfaRequest.reducer,
  locale: locale.reducer,
  resetPassword: resetPassword.reducer,
  units: units.reducer,
  notifications: notifications.reducer,
  router: connectRouter(history),
})
const rootReducer = (state: State, action: AnyAction) => {
  if (action.type === 'ACCOUNT/logout_result') {
    state = undefined
  }

  return appReducer(state, action)
}
/* eslint-disable */
const store = createStore(
  rootReducer,
  loadState(),
  composeWithDevTools({ trace: true })(
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  )
)

sagaMiddleware.run(rootSaga)

export default store
