import * as React from 'react'
import { Row, Col, Switch, Button, Typography } from 'antd'
import styled from 'styled-components'
import { updateAccountAction } from '../types'
import { connect } from 'react-redux'
import { pipe } from 'fp-ts/lib/pipeable'
import { loadable } from '../../../shared/types'
import { option } from 'fp-ts'

import { emptyAccount } from '../../Authentication/Login'

import { State } from '../../../shared/state/store'
import { FormattedMessage } from 'react-intl'

const { Title } = Typography

const Twofactor = styled(Title)`
  color: #51b148 !important;

  font-size: 21px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 2px;
`

const Save = styled(Button)`
  width: 251px;
  height: 32px;
`

const mapStateToProps = ({
  accountSetting: { updateResult },
  account: { account },
}: State) => ({
  account: pipe(
    account,
    loadable.toOption,
    option.flatten,
    option.getOrElse(() => emptyAccount)
  ),
  updateLoading: loadable.isLoading(updateResult),
})

const mapDispatchToProps = {
  setTFA: (tfaEnabled: boolean) => updateAccountAction({ tfaEnabled }),
  /*   openChangePassword: openChangePasswordForm,
   */
}
type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const TwoFactorSwitch = ({ updateLoading, account, setTFA }: Props) => {
  const check = account.tfaEnabled
  const [switchCheck, setSwitchCheck] = React.useState(check)
  return (
    <>
      <Row gutter={[40, 30]}>
        <Col>
          <Twofactor level={4}>
            {' '}
            <FormattedMessage id={'towFactor'} />
          </Twofactor>
        </Col>
      </Row>
      <Row gutter={[40, 30]}>
        <Col span={8}>
          <FormattedMessage id={'enableTwoFactor'} />
        </Col>
        <Col span={6}>
          <Switch
            loading={updateLoading}
            checked={switchCheck}
            onChange={setSwitchCheck}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Save
            type="primary"
            onClick={() => {
              setTFA(switchCheck)
            }}
          >
            <FormattedMessage id={'save'} />
          </Save>
        </Col>
      </Row>
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorSwitch)
