import {
  Loadable,
  FormData,
  PayloadAction,
  payloadActionCreator,
  Action,
  actionCreator,
  FailableAction,
  failableActionCreator,
} from '../../../shared/types'
import { LocationChangeAction } from 'connected-react-router'

export type FinishReset = {
  newPassword: string
  confirmPassword: string
  key: string
}

export type State = {
  initRequest: FormData<{ email: string }>
  finishRequest: FormData<FinishReset>
  initRequestResult: Loadable<unknown>
  finishRequestResult: Loadable<unknown>
}

export type UpdateInitRequest = PayloadAction<
  'RESET/update_init_request',
  Partial<{ email: string }>
>
export const updateInitRequest = payloadActionCreator<UpdateInitRequest>(
  'RESET/update_init_request'
)

export type UpdateFinishRequest = PayloadAction<
  'RESET/update_finish_request',
  Partial<FinishReset>
>
export const updateFinishRequest = payloadActionCreator<UpdateFinishRequest>(
  'RESET/update_finish_request'
)

export type ConfirmInitRequest = Action<'RESET/confirm_init_request'>
export const confirmInitRequest = actionCreator<ConfirmInitRequest>(
  'RESET/confirm_init_request'
)

export type InitRequestResult = FailableAction<
  'RESET/init_request_result',
  undefined
>
export const initRequestResult = failableActionCreator<InitRequestResult>(
  'RESET/init_request_result'
)

export type ConfirmFinishRequest = Action<'RESET/confirm_finish_request'>
export const confirmFinishRequest = actionCreator<ConfirmFinishRequest>(
  'RESET/confirm_finish_request'
)

export type FinishRequestResult = FailableAction<
  'RESET/finish_request_result',
  undefined
>
export const finishRequestResult = failableActionCreator<FinishRequestResult>(
  'RESET/finish_request_result'
)

export type Actions =
  | UpdateInitRequest
  | UpdateFinishRequest
  | ConfirmInitRequest
  | InitRequestResult
  | ConfirmFinishRequest
  | FinishRequestResult
  | LocationChangeAction
