import * as t from './types'
import * as api from './api'
import * as effects from 'redux-saga/effects'
import {
  NotRequested,
  getFormDataFromPartialForm,
  Loading,
  result,
  extractFormData,
  prismEmail,
  foldEitherToAction,
} from '../../../shared/types'
import { pipe } from 'fp-ts/lib/pipeable'
import { lens } from 'lens.ts'
import { CancelToken } from 'axios'
import { State } from '../../../shared/state/store'
import { takeLatest } from '../../../shared/state/saga'
import { option, task } from 'fp-ts'
import { push } from 'connected-react-router'

export const initialState: t.State = {
  userEmail: { email: { value: '', pristine: true } },
  requestResult: NotRequested,
}

const _l = lens<t.State>()

export const reducer = (s = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'TFA/update':
      return pipe(
        s,
        _l.userEmail.set((f) => ({
          ...f,
          ...getFormDataFromPartialForm(a.payload),
        }))
      )
    case 'TFA/request':
      return pipe(
        s,
        _l.requestResult.set((p) =>
          pipe(
            s.userEmail,
            extractFormData,
            option.chain((v) => prismEmail.getOption(v.email)),
            option.map(() => Loading),
            option.getOrElse(() => p)
          )
        )
      )
    case 'TFA/request_result':
      return pipe(s, _l.requestResult.set(a.payload))
    case '@@router/LOCATION_CHANGE':
      return initialState
    default:
      return s
  }
}

function* requestTFASaga(_: t.RequestTFAAction, token: CancelToken) {
  const req: State['tfaRequest']['userEmail'] = yield effects.select(
    (s: State) => s.tfaRequest.userEmail
  )
  const email = pipe(
    req,
    extractFormData,
    option.chain((v) => prismEmail.getOption(v.email))
  )
  if (option.isNone(email)) {
    return
  }
  yield yield effects.call(
    pipe(
      api.requestTFACode(email.value, token),
      task.map(foldEitherToAction(t.requestTFResult)),
      task.map((a) => effects.put(a))
    )
  )
}

function* requestResultSaga(a: t.RequestTFAResult) {
  if (result.isErr(a.payload)) {
    return
  }
  yield effects.delay(5000)
  yield effects.put(push('/'))
}

export function* saga() {
  yield effects.all([
    takeLatest('TFA/request', requestTFASaga),
    takeLatest('TFA/request_result', requestResultSaga),
  ])
}
