import { Alert, Button, Col, Form, Input, Row } from 'antd'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../../shared/state/store'
import { loadable } from '../../../../shared/types'
import {
  updateOrganizationSignUpFormAction,
  verifyOrganizationExistsAction,
} from '../../Login/types'

const mapStateToProps = ({ account: { verifyOrganizationExists } }: State) => ({
  verifyOrganizationExists: pipe(
    verifyOrganizationExists,
    loadable.caseOf({
      Ok: () => true,
      _: () => false,
      Err: () => false,
    })
  ),
  enableNextStep:
    loadable.isNotRequested(verifyOrganizationExists) ||
    loadable.isOk(verifyOrganizationExists),
  loading: loadable.isLoading(verifyOrganizationExists),
})
const FirstStepForm = styled(Form)`
  display: grid;
  grid-gap: 2px;
  overflow: hidden;
`
const mapDispatchToProps = {
  updateSignUpFormAction: updateOrganizationSignUpFormAction,
  verifyOrganizationExistsAction: verifyOrganizationExistsAction,
}
type OwnProps = { next: any }
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps
type Props = StateProps & DispatchProps & OwnProps

const OrganizationStep = ({
  next,
  verifyOrganizationExists,
  enableNextStep,
  loading,
  updateSignUpFormAction,
  verifyOrganizationExistsAction,
}: Props) => {
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    updateSignUpFormAction(values)
    next()
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const onchange = () => {
    form.setFieldsValue({
      organizationUrl: `${form.getFieldValue('organizationName')}.tech`,
    })
    verifyOrganizationExistsAction(form.getFieldValue('organizationName'))
  }
  return (
    <>
      <FirstStepForm
        form={form}
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={
            <FormattedMessage
              id="organizationName"
              defaultMessage="organization Name"
            />
          }
          name="organizationName"
          hasFeedback
          required
          validateStatus={verifyOrganizationExists ? 'error' : ''}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="missingCompanyNameMsg"
                  defaultMessage="Please input your Organization Name!"
                />
              ),
            },
          ]}
        >
          <Input allowClear onChange={onchange} />
        </Form.Item>

        <Form.Item
          required
          label={
            <FormattedMessage
              id="OrganizationUrl"
              defaultMessage="Organization Url"
            />
          }
          name="organizationUrl"
        >
          <Input
            value={`${form.getFieldValue('organizationName')}.tech`}
            disabled
          />
        </Form.Item>
        {verifyOrganizationExists ? (
          <Form.Item>
            <Alert
              style={{ textAlign: 'center' }}
              message={
                <FormattedMessage
                  id="OrganizationNameExist"
                  defaultMessage="Organization Name Exist"
                />
              }
              type="error"
            />
          </Form.Item>
        ) : null}

        <Row justify="end">
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={enableNextStep}
            >
              <FormattedMessage id="Next" defaultMessage="Next" />
            </Button>
          </Col>
        </Row>
      </FirstStepForm>
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationStep)
