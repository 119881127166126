import { CancelToken } from 'axios'
import { array, either, option, task, taskEither } from 'fp-ts'
import { sequenceT } from 'fp-ts/lib/Apply'
import { pipe } from 'fp-ts/lib/pipeable'
import { lens } from 'lens.ts'
import * as effects from 'redux-saga/effects'

import { clearCookies } from '../../../axios'
import { takeLatest } from '../../../shared/state/saga'
import { State } from '../../../shared/state/store'
import {
  foldEitherToAction,
  loadable,
  Loading,
  NotRequested,
  result,
  Unpacked,
} from '../../../shared/types'
import {
  getFormData,
  getFormDataFromPartialForm,
} from '../../../shared/types/form'
import * as account from './../../../components/Account'
import * as api from './api'
import * as t from './types'

export const initialState: t.State = {
  redirect: option.none,
  account: NotRequested,
  login: {
    username: { value: '', pristine: true },
    password: { value: '', pristine: true },
    tfacode: { value: '', pristine: true },
  },
  signup: {
    login: { value: '', pristine: true },

    firstName: { value: '', pristine: true },
    email: { value: '', pristine: true },
    lastName: { value: '', pristine: true },
  },
  organizationSignUp: {
    organizationName: { value: '', pristine: true },
    email: { value: '', pristine: true },
    userName: { value: '', pristine: true },
    organizationUrl: { value: '', pristine: true },
  },
  loginResult: NotRequested,
  signupResult: NotRequested,
  verifyOrganizationExists: NotRequested,
  verifyEmailExists: NotRequested,
  organizationSignupResult: NotRequested,
  verifyUserNameExists: NotRequested,
}

const _l = lens<t.State>()

export const reducer = (s = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'ACCOUNT/get_account':

    case 'ACCOUNT/logout':
      return _l.account.set(Loading)(s)

    case 'ACCOUNT/login':
      return _l.account.set(Loading)(s)
    case 'ACCOUNT/signup':
      return { ...s }
    case 'ACCOUNT/send_googleAccessToken':
      return pipe(s, _l.account.set(Loading), _l.loginResult.set(Loading))
    case 'ACCOUNT/signup_result':
      return pipe(s, _l.signupResult.set(a.payload))
    case 'ACCOUNT/update_result':
      return pipe(
        s,
        _l.account.set((acc) =>
          pipe(
            acc,
            loadable.map(
              option.map((acc) =>
                pipe(
                  a.payload,
                  result.caseOf({
                    Ok: (p) => ({ ...acc, ...p }),
                    Err: () => acc,
                  })
                )
              )
            )
          )
        )
      )
    case 'ACCOUNT/get_account_result':
      return pipe(
        s,
        _l.account.set(pipe(a.payload, result.map(option.some))),
        _l.login.set((l) =>
          pipe(
            a.payload,
            result.map(() => ({ ...initialState.login })),
            result.getOrElse(() => l)
          )
        )
      )
    case 'ACCOUNT/login_result':
      return pipe(
        s,
        _l.loginResult.set(a.payload),
        _l.account.set((acc) =>
          result.isOk(a.payload) && a.payload.value === '2FA'
            ? NotRequested
            : acc
        )
      )
    case 'ACCOUNT/logout_result':
      return pipe(
        s,
        _l.account.set(
          pipe(
            a.payload,
            result.map(() => option.none)
          )
        )
      )
    case 'ACCOUNT/update_form':
      return pipe(
        s,
        _l.login.set((l) => ({
          ...l,
          ...getFormDataFromPartialForm(a.payload),
        }))
      )
    case 'ACCOUNT/update_signup_form':
      return pipe(
        s,
        _l.signup.set((l) => ({
          ...l,
          ...getFormDataFromPartialForm(a.payload),
        }))
      )
    case 'ACCOUNT/update_organization_signup_form':
      return pipe(
        s,
        _l.organizationSignUp.set((l) => ({
          ...l,
          ...getFormDataFromPartialForm(a.payload),
        }))
      )
    case 'ACCOUNT/Verify_USER_NAME_Exists':
      return pipe(s, _l.verifyUserNameExists.set(Loading))
    case 'ACCOUNT/Verify_USER_NAME_Exists_result':
      return pipe(s, _l.verifyUserNameExists.set(a.payload))
    case 'ACCOUNT/Verify_Organization_Exists':
      return pipe(s, _l.verifyOrganizationExists.set(Loading))
    case 'ACCOUNT/Verify_Organization_Exists_result':
      return pipe(s, _l.verifyOrganizationExists.set(a.payload))
    case 'ACCOUNT/Verify_Email_Exists':
      return pipe(s, _l.verifyEmailExists.set(Loading))
    case 'ACCOUNT/Verify_Email_Exists_result':
      return pipe(s, _l.verifyEmailExists.set(a.payload))

    case 'ACCOUNT/Organization_signUp':
      return pipe(s, _l.organizationSignupResult.set(Loading))
    case 'ACCOUNT/Organization_signUp_result':
      return pipe(s, _l.organizationSignupResult.set(a.payload))
    case 'ACCOUNT/reset_login':
      return pipe(
        s,
        _l.login.set(initialState.login),
        _l.loginResult.set(initialState.loginResult)
      )
    case 'ACCOUNT/reset_signup':
      return pipe(s, _l.signup.set(initialState.signup))
    case '@@router/LOCATION_CHANGE':
      return pipe(
        s,
        _l.login.set(initialState.login),
        _l.loginResult.set(initialState.loginResult),
        (s) =>
          pipe(
            new URLSearchParams(a.payload.location.search).get('redirect'),
            option.fromNullable,
            option.map((value) => _l.redirect.set(option.some(value))(s)),
            option.getOrElse(() => s)
          )
      )
    case 'ACCOUNT/Reset_Verify_Organization_Signup':
      return pipe(
        s,
        _l.verifyEmailExists.set(NotRequested),
        _l.verifyOrganizationExists.set(NotRequested)
      )

    default:
      return s
  }
}

function* getAccountSaga(_: t.GetAccountAction, token: CancelToken) {
  yield yield effects.call(
    pipe(
      sequenceT(taskEither.taskEither)(
        api.getAccount(token),
        api.getWallets(token)
      ),
      taskEither.map(([acc, wallets]) => ({ ...acc, wallets })),
      task.map(
        either.fold(
          (e) => effects.all([effects.put(t.getAccountResultAction.err(e))]),
          (acc) =>
            effects.all([
              effects.put(t.getAccountResultAction.ok(acc)),
              acc.organizations.length === 1
                ? acc.organizations[0]?.authorities === 'ORGANIZATION_ADMIN'
                  ? effects.all([
                      effects.put(
                        account.getOrganizationUsers(acc.organizations[0]?.name)
                      ),
                      effects.put(
                        account.getOrganizationAction(
                          acc.organizations[0]?.name
                        )
                      ),
                    ])
                  : effects.all([
                      effects.put(
                        account.getOrganizationAction(
                          acc.organizations[0]?.name
                        )
                      ),
                    ])
                : effects.put(account.openAccountsModal()),
            ])
        )
      )
    )
  )
}

function* loginSaga(_: t.LoginAction, token: CancelToken) {
  const login: t.State['login'] = yield effects.select(
    (s: State) => s.account.login
  )
  const loginData = getFormData(login)
  yield yield effects.call(
    pipe(
      api.signIn(loginData, token),
      task.map(
        either.fold<Error, t.LoginStatus, t.Actions[]>(
          (r): t.Actions[] => [
            t.getAccountResultAction.err(r),
            t.loginResultAction.err(r),
          ],
          (r) =>
            r === '2FA'
              ? [t.loginResultAction.ok(r)]
              : [t.getAccountAction(), t.loginResultAction.ok(r)]
        )
      ),
      task.map(array.map((a) => effects.put(a))),
      task.map((es) => effects.all(es))
    )
  )
}
function* signUpSaga(_: t.SignupAction, token: CancelToken) {
  const signup: t.State['signup'] = yield effects.select(
    (s: State) => s.account.signup
  )
  const signupData = {
    ...getFormData(signup),
    activated: true,
    authorities: ['USER'],
    tfaEnabled: 'false',
  }

  yield yield pipe(
    api.signup(signupData, token),

    task.map(foldEitherToAction(t.signupActionResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  )
}
function* organizationSignUpSaga(
  a: t.OrganizationSignUpAction,
  token: CancelToken
) {
  yield yield pipe(
    api.organizationSignUp(a.payload, token),

    task.map(foldEitherToAction(t.organizationSignUpResultAction)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  )
}
function* googleLoginSaga(a: t.SendgoogleAccessToken, token: CancelToken) {
  yield yield pipe(
    api.googleSignIn(a.payload, token),
    task.map(
      either.fold(
        (e) => [t.loginResultAction.err(e)],
        (r) => [t.getAccountAction(), t.loginResultAction.ok(r)]
      )
    ),

    task.map(array.map((a) => effects.put(a))),
    task.map((es) => effects.all(es)),
    (e) => effects.call(e)
  )
}

function* loginResultSaga(a: t.LoginResultAction) {
  if (result.isErr(a.payload) || a.payload.value === '2FA') {
    return
  }
  const target: State['account']['redirect'] = yield effects.select(
    (s: State) => s.account.redirect
  )
  pipe(
    target,
    option.map((t) => () => {
      window.location.pathname = t
    }),
    option.getOrElse(() => () => {})
  )()
}

function* logoutSaga(_: t.LogoutAction, token: CancelToken) {
  const res: Unpacked<typeof api.logout> = yield effects.call(api.logout(token))
  const action = foldEitherToAction(t.logoutResultAction)(res)
  clearCookies()()
  yield effects.all([effects.put(action), effects.put(t.getAccountAction())])
}

function* verifyOrganizationExistsSaga(
  a: t.VerifyOrganizationExistsAction,
  token: CancelToken
) {
  yield yield effects.call(
    pipe(
      api.verifyOrganizationNameExists(a.payload, token),
      task.map(
        either.fold(
          (e) => [t.verifyOrganizationExistsResultAction.err(e.message)],
          () => [t.verifyOrganizationExistsResultAction.ok(undefined)]
        )
      ),
      task.map(array.map((a) => effects.put(a))),
      task.map((ps) => effects.all(ps))
    )
  )
}
function* verifyUserNameExistsSaga(
  a: t.VerifyUserNameExistsAction,
  token: CancelToken
) {
  yield yield effects.call(
    pipe(
      api.verifyUserNameExists(a.payload, token),
      task.map(
        either.fold(
          (e) => [t.verifyUserNameExistsResultAction.err(e.message)],
          () => [t.verifyUserNameExistsResultAction.ok(undefined)]
        )
      ),
      task.map(array.map((a) => effects.put(a))),
      task.map((ps) => effects.all(ps))
    )
  )
}
function* verifyEmailExistsSaga(
  a: t.VerifyEmailExistsAction,
  token: CancelToken
) {
  yield yield effects.call(
    pipe(
      api.verifyEmailNameExists(a.payload, token),
      task.map(
        either.fold(
          (e) => [t.verifyEmailExistsResultAction.err(e.message)],
          (arr) => [t.verifyEmailExistsResultAction.ok(arr)]
        )
      ),
      task.map(array.map((a) => effects.put(a))),
      task.map((ps) => effects.all(ps))
    )
  )
}

export function* saga() {
  yield effects.all([
    takeLatest('ACCOUNT/send_googleAccessToken', googleLoginSaga),
    takeLatest('ACCOUNT/signup', signUpSaga),
    takeLatest('ACCOUNT/login', loginSaga),
    takeLatest('ACCOUNT/logout', logoutSaga),
    takeLatest('ACCOUNT/get_account', getAccountSaga),
    takeLatest('ACCOUNT/login_result', loginResultSaga),
    takeLatest(
      'ACCOUNT/Verify_Organization_Exists',
      verifyOrganizationExistsSaga
    ),
    takeLatest('ACCOUNT/Verify_Email_Exists', verifyEmailExistsSaga),
    takeLatest('ACCOUNT/Organization_signUp', organizationSignUpSaga),
    takeLatest('ACCOUNT/Verify_USER_NAME_Exists', verifyUserNameExistsSaga),
  ])
}
