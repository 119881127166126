import { UserOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Tag,
  Upload,
} from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import { either, option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import { TranslatedMessage } from '../../../shared/translations/data'
import { Action, loadable, noopAction } from '../../../shared/types'
import { emptyAccount, UAAAccount } from '../../Authentication/Login'
import {
  updateAccountAction,
  updateSelectedFile,
  uploadImageAction,
} from '../types'

const Save = styled(Button)`
  width: 251px;
  height: 32px;
`

const mapStateToProps = ({
  account: { account },
  accountSetting: { selectedFile, uploadImageResult, updateResult },
}: State) => {
  const a = pipe(
    account,
    loadable.toOption,
    option.flatten,
    option.getOrElse((): UAAAccount => emptyAccount),
    UAAAccount.encode
  )

  return {
    selectedFile,
    account: a,
    accountUpdated: loadable.isLoading(updateResult),
    imageLoading: loadable.isLoading(uploadImageResult),
    imageUrl: pipe(
      selectedFile,
      option.map((f) => URL.createObjectURL(f)),
      option.alt(() =>
        pipe(
          option.fromNullable(a.imageUrl),
          option.map((imageUrl) => `/uaa/api/account/picture/${imageUrl}`)
        )
      )
    ),
  }
}

const mapDispatchToProps = {
  updateSelectedFile,
  uploadImageAction,
  updateAccountAction,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

const mergeProps = ({ selectedFile, ...s }: StateProps, d: DispatchProps) => ({
  ...s,
  updateSelectedFile: d.updateSelectedFile,
  uploadImage: () =>
    pipe(
      selectedFile,
      option.fold(
        () => {},
        (f) => {
          const file = new FormData()
          file.append('file', f)
          d.uploadImageAction({ file })
        }
      )
    ),
  updateAccount: (x: object) =>
    pipe(
      UAAAccount.decode({
        ...s.account,
        ...x,
      }),

      either.fold((): Action<string> => noopAction(), d.updateAccountAction)
    ),
})

type Props = ReturnType<typeof mergeProps>

const PersonalInformationFormComponent = ({
  account,
  updateSelectedFile,
  uploadImage,
  updateAccount,
  accountUpdated,
  imageLoading,
  imageUrl,
}: Props) => (
  <div>
    <Row gutter={[40, 30]}>
      <Col span={4} style={{ marginLeft: 30 }}>
        <TranslatedMessage id="picture" />
      </Col>
      <Col span={4}>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={(file) => {
            if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
              message.error('You can only upload JPG/PNG file!')
              return false
            }
            if (file.size > 2 * 1024 * 1024) {
              message.error('Image must smaller than 2MB!')
              return false
            }
            updateSelectedFile(file)
            return false
          }}
        >
          {pipe(
            imageUrl,
            option.fold(
              () => (
                <div>
                  <Avatar
                    size="large"
                    icon={
                      <UserOutlined
                        style={{
                          color: 'white',
                        }}
                      />
                    }
                  />
                  <div style={{ marginTop: 8 }}>
                    <TranslatedMessage id="selectPicture" />
                  </div>
                </div>
              ),
              (imageUrl) => <img src={imageUrl} style={{ width: '100%' }} />
            )
          )}
        </Upload>
      </Col>
    </Row>
    <Row gutter={[8, 8]}>
      <Col>
        <Save loading={imageLoading} type="primary" onClick={uploadImage}>
          <TranslatedMessage id="save" />
        </Save>
      </Col>
    </Row>
    <Form
      initialValues={account}
      onFinish={({ organizations: _, ...rest }) => updateAccount(rest)}
      labelCol={{ lg: 10, md: 8 }}
      wrapperCol={{ span: 12 }}
    >
      <Row justify="start">
        <Col lg={12} md={24}>
          <Form.Item
            label={<TranslatedMessage id="username" />}
            name="login"
            rules={[
              {
                required: true,
                message: <TranslatedMessage id="missingUsernameMsg" />,
              },
            ]}
          >
            <Input placeholder="Username" />
          </Form.Item>
        </Col>
        <Col lg={12} md={24}>
          <Form.Item
            label={<TranslatedMessage id="emailLabel" />}
            name="email"
            rules={[
              {
                required: true,
                message: <TranslatedMessage id="missingEmailMsg" />,
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={24}>
          <Form.Item
            label={<TranslatedMessage id="firstName" />}
            name="firstName"
            rules={[
              {
                required: true,
                message: <TranslatedMessage id="missingFirstnameMsg" />,
              },
            ]}
          >
            <Input placeholder="FirstName" />
          </Form.Item>
        </Col>
        <Col lg={12} md={24}>
          <Form.Item
            label={<TranslatedMessage id="lastName" />}
            name="lastName"
            rules={[
              {
                required: true,
                message: <TranslatedMessage id="missingLastnameMsg" />,
              },
            ]}
          >
            <Input placeholder="LasName" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={24}>
          <Form.Item label={<TranslatedMessage id="organizationLabel" />}>
            {account.organizations.map((org) => (
              <Tag key={org.name}>{org.name}</Tag>
            ))}
          </Form.Item>
        </Col>
        <Col lg={12} md={24}>
          <Form.Item
            label={<TranslatedMessage id="jobTitle" />}
            name="jobTitle"
          >
            <Input placeholder="Job Title" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Form.Item name="langKey" label={<TranslatedMessage id="language" />}>
            <Radio.Group>
              <Radio value="en">
                <TranslatedMessage id="en" />
              </Radio>
              <Radio value="fr">
                <TranslatedMessage id="fr" />
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[8, 8]}>
        <Col>
          <Save htmlType="submit" type="primary" loading={accountUpdated}>
            <TranslatedMessage id="save" />
          </Save>
        </Col>
      </Row>
    </Form>
  </div>
)
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PersonalInformationFormComponent)
