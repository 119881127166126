import * as axios from '../../../axios'
import * as t from 'io-ts'
import { CancelToken } from 'axios'
import { FinishReset } from './types'
import { pipe } from 'fp-ts/lib/pipeable'
import { taskEither } from 'fp-ts'
import { Email, isoEmail } from '../../../shared/types'

const apiUrl = '/uaa/api/account/reset-password'

export const resetPassword = (email: Email, cancelToken?: CancelToken) =>
  pipe(
    axios.post(`${apiUrl}/init`, isoEmail.unwrap(email), {
      cancelToken,
      headers: { 'Content-Type': 'text/plain' },
      decoder: t.unknown,
    }),
    taskEither.map(() => undefined)
  )

export const confirmResetPassword = (
  req: FinishReset,
  cancelToken?: CancelToken
) =>
  pipe(
    axios.post(`${apiUrl}/finish`, req, { cancelToken, decoder: t.unknown }),
    taskEither.map(() => undefined)
  )
