import {
  CreditCardOutlined,
  EditOutlined,
  FundFilled,
  SettingFilled,
  ShopOutlined,
  WalletFilled,
} from '@ant-design/icons'
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Switch,
} from 'antd'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { emptyOrganization, Organization, putOrganizationUsers } from '../..'
import { State } from '../../../../shared/state/store'
import { loadable } from '../../../../shared/types'
import { applications } from '../../types'

const mapStateToProps = ({ accountSetting: { organization } }: State) => {
  const currentOrganization = pipe(
    organization,
    loadable.getOrElse((): Organization => emptyOrganization)
  )

  const permissions = currentOrganization.modules

  return {
    permissions: permissions,
  }
}
const images = {
  WALLET: <WalletFilled />,
  BILLS: <CreditCardOutlined />,
  MARKETPLACE: <ShopOutlined />,
  DASHBOARD: <FundFilled />,
  BACKOFFICE: <SettingFilled />,
}
type OwnProps = {
  user: any
  organizationName: string
}

const mapDispatchToProps = { putOrganizationUsers: putOrganizationUsers }
type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  OwnProps

const UserEditModal = ({
  user,
  organizationName,
  permissions,
  putOrganizationUsers,
}: Props) => {
  const [visible, setVisible] = React.useState(false)
  const [form] = Form.useForm()

  return (
    <>
      <Button
        icon={<EditOutlined />}
        onClick={() => {
          setVisible(true)
        }}
      />

      <Modal
        width={700}
        title={
          <FormattedMessage
            id="AddOrganizationUser"
            defaultMessage="Add Organization User"
          />
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            form="userEditForm"
            key="close"
            onClick={() => setVisible(false)}
          >
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>,
          <Button
            type="primary"
            form="userEditForm"
            key="submit"
            htmlType="submit"
          >
            <FormattedMessage id="save" defaultMessage="Save" />
          </Button>,
        ]}
      >
        <Form
          name="userEditForm"
          form={form}
          size="small"
          labelAlign="left"
          labelCol={{ span: 8 }}
          initialValues={{
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            admin: user.admin === 'ORGANIZATION_ADMIN' ? true : false,

            DASHBOARD: user.permissions.DASHBOARD,
            BACKOFFICE: user.permissions.BACKOFFICE,
            WALLET: user.permissions.WALLET,
            MARKETPLACE: user.permissions.MARKETPLACE,
            BILLS: user.permissions.BILLS,
          }}
          onFinish={(values) => {
            const editUser = {
              id: user.id,
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              organizations: [
                {
                  name: organizationName,
                  permissions: {
                    DASHBOARD: values.DASHBOARD ? values.DASHBOARD : 'NONE',
                    BACKOFFICE: values.BACKOFFICE ? values.BACKOFFICE : 'NONE',
                    WALLET: values.WALLET ? values.WALLET : 'NONE',
                    MARKETPLACE: values.MARKETPLACE
                      ? values.MARKETPLACE
                      : 'NONE',
                    BILLS: values.BILLS ? values.BILLS : 'NONE',
                  },
                  authorities: values.admin
                    ? 'ORGANIZATION_ADMIN'
                    : 'ORGANIZATION_USER',
                },
              ],

              login: `${values.firstName}${values.lastName}`.replace(/\s/g, ''),
            }
            putOrganizationUsers({ organizationName, user: editUser })
          }}
        >
          <Form.Item
            label={<FormattedMessage id="email" defaultMessage="Email" />}
            name="email"
            required
            hasFeedback
            tooltip="This is a required field"
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="missingEmailMsg"
                    defaultMessage="Please input your Email!"
                  />
                ),
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage id="fullName" defaultMessage="Full Name" />
            }
            hasFeedback
            required
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="missingFullNameMsg"
                    defaultMessage="Please input your Full Name!"
                  />
                ),
              },
            ]}
            tooltip="This is a required field"
            style={{ marginBottom: 0 }}
          >
            <Row justify="space-between">
              <Col span={11}>
                <Form.Item
                  tooltip="This is a required field"
                  name="firstName"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="missingFirstnameMsg"
                          defaultMessage="Please input your First Name!"
                        />
                      ),
                    },
                  ]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  tooltip="This is a required field"
                  name="lastName"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="missingLastnameMsg"
                          defaultMessage="Please input your Last Name!"
                        />
                      ),
                    },
                  ]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage id="Permissions" defaultMessage="Permissions" />
            }
            labelCol={{ span: 24 }}
          >
            {applications.map((app) => (
              <>
                {permissions.includes(app.name) ? (
                  <Row key={app.descriptionKey} justify="end">
                    <Col span={2}>
                      <Avatar icon={images[app.name]} />
                    </Col>
                    <Col span={6}> {app.name}</Col>
                    <Col span={14}>
                      <Form.Item name={app.name}>
                        <Radio.Group>
                          <Radio.Button value="CONTRIBUTOR">
                            <FormattedMessage
                              id="Contributor"
                              defaultMessage="Contributor"
                            />
                          </Radio.Button>
                          <Radio.Button value="VIEWER">
                            <FormattedMessage
                              id="Viewer"
                              defaultMessage="Viewer"
                            />
                          </Radio.Button>
                          <Radio.Button value="NONE">
                            <FormattedMessage id="None" defaultMessage="None" />
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
              </>
            ))}
          </Form.Item>
          <Form.Item
            name="admin"
            label={
              <FormattedMessage id="ROLE_ADMIN" defaultMessage="ROLE_ADMIN" />
            }
            valuePropName="checked"
          >
            <Switch size="default" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditModal)
