import { option, record } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'

type FormField<T> = { value: T; pristine: boolean }

export type FormData<T extends Record<string, any>> = {
  [K in keyof T]: FormField<T[K]>
}

export const extractFormData = <T extends object>(
  f: FormData<T>
): option.Option<T> => {
  for (const k in f) {
    const v = f[k]
    if (v.pristine) {
      return option.none
    }
  }
  return option.some(getFormData(f))
}

export const getFormData = <T extends object>(f: FormData<T>): T => {
  let res: Partial<T> = {}
  for (const k in f) {
    const v = f[k]
    res = { ...res, [k]: v.value }
  }
  return res as T
}

export const getMissing = <T extends Record<string, string>>(
  f: FormData<T>
): Record<keyof T, boolean> => {
  let res: Partial<{ [K in keyof T]: boolean }> = {}
  for (const k in f) {
    const v = f[k]
    res = { ...res, [k]: v.value === '' && !v.pristine }
  }
  return res as { [K in keyof T]: boolean }
}

export const getFormDataFromPartialForm = <T extends Record<string, string>>(
  f: Partial<T>
): Partial<FormData<T>> =>
  pipe(
    // @ts-ignore
    f,
    record.filterMap(option.fromNullable),
    record.map((value) => ({ value, pristine: false }))
  )
