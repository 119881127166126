import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Col, Row, Space, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { emptyOrganization, Organization } from '..'
import { applications } from '../../../components/Account/types'
import { State } from '../../../shared/state/store'
import { loadable } from '../../../shared/types'
import {
  AccountOrganizationPermission,
  UAAAccount,
} from '../../Authentication/Login'
import UserAddModal from './userModals/UserAddModal'
import UserDeleteModal from './userModals/UserDeleteModal'
import UserEditModal from './userModals/UserEditModal'

const mapStateToProps = ({
  accountSetting: { organizationUsers, organization },
}: State) => {
  const currentOrganization = pipe(
    organization,
    loadable.getOrElse((): Organization => emptyOrganization)
  )

  const permissions = currentOrganization.modules

  return {
    permissionsOrg: permissions,

    users: pipe(
      organizationUsers,
      loadable.getOrElse((): UAAAccount[] => [])
    ),
    organizationName: pipe(
      organization,
      loadable.map((t) => t.name),
      loadable.getOrElse((): string => '')
    ),
    loading: !loadable.isOk(organizationUsers),
  }
}

const mapDispatchToProps = {}
type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
const UserManagement = ({
  users,
  organizationName,
  loading,
  permissionsOrg,
}: Props) => {
  const columns: ColumnsType<{
    key: number
    id: number
    user: string
    email: string
    firstName: string
    lastName: string
    admin: string
    permissions: {
      DASHBOARD: string
      BACKOFFICE: string
      WALLET: string
      MARKETPLACE: string
      BILLS: string
    }
  }> = [
    {
      title: <FormattedMessage id="ROLE_USER" defaultMessage="USER" />,
      dataIndex: 'user',
      key: 'user',
      align: 'left',
      className: 'userTable',
      render: (text: any) => {
        return (
          <Space direction="vertical" size="small">
            {text ? text : 'N/A'}
          </Space>
        )
      },
    },

    {
      title: <FormattedMessage id="emailLabel" defaultMessage="Email" />,
      dataIndex: 'email',
      key: 'email',
      align: 'left',
      className: 'userTable',
      render: (text: any) => {
        return (
          <Row style={{ verticalAlign: 'baseline' }} gutter={10}>
            {text ? text : 'N/A'}
          </Row>
        )
      },
    },
    {
      title: <FormattedMessage id="Permissions" defaultMessage="Permissions" />,
      dataIndex: 'permissions',
      key: 'permissions',
      align: 'left',
      render: (permissions: AccountOrganizationPermission) => {
        return (
          <Space direction="vertical" size="small">
            {applications.map((app) => (
              <React.Fragment key={app.descriptionKey}>
                {permissionsOrg.includes(app.name) ? (
                  <Typography.Text
                    key={app.descriptionKey}
                    style={{ textAlign: 'center' }}
                  >
                    {app.name} ({permissions?.[app.name]})
                  </Typography.Text>
                ) : null}
              </React.Fragment>
            ))}
          </Space>
        )
      },
    },
    {
      dataIndex: 'admin',
      key: 'admin',
      align: 'center',
      render: (text: any) => {
        return (
          <Row justify="space-around">
            {text === 'ORGANIZATION_ADMIN' ? (
              <CheckOutlined style={{ color: 'green' }} />
            ) : (
              <CloseOutlined style={{ color: 'red' }} />
            )}
          </Row>
        )
      },
    },

    {
      key: 'action',
      align: 'left',
      render: (_: any, record: any) => {
        return (
          <Row justify="end">
            <Space size="middle">
              <UserDeleteModal user={record} />
              <UserEditModal
                user={record}
                organizationName={organizationName}
              />
            </Space>
          </Row>
        )
      },
    },
  ]

  const data = users.map((t) => {
    return {
      key: t?.id,
      id: t?.id,
      user: t?.login,
      email: pipe(
        t?.email,
        option.getOrElse((): string => '')
      ),

      firstName: pipe(
        t?.firstName,
        option.getOrElse((): string => '')
      ),
      lastName: pipe(
        t?.lastName,
        option.getOrElse((): string => '')
      ),
      admin: t?.organizations[0].authorities,
      permissions: t?.organizations[0].permissions,
    }
  })

  return (
    <Row gutter={[30, 30]}>
      <Col span={24}>
        <UserAddModal organizationName={organizationName} />
      </Col>
      <Col span={24}>
        <Table
          size="small"
          columns={columns}
          dataSource={data}
          loading={loading}
          className="userTable"
        />
      </Col>
    </Row>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)
