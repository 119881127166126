import { Col, Layout, Row } from 'antd'
import { option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components'

import logo from '../../../assets/logoHeader.png'
import {
  emptyAccount,
  logoutAction,
  UAAAccount,
} from '../../../components/Authentication/Login'
import { State } from '../../state/store'
import { loadable } from '../../types'
import AccountSettingsDropDown from './AccountSettingsDropDown'
import OrganizationSettingsDropDown from './OrganizationSettingsDropDown'

const mapStateToProps = ({ account }: State) => ({
  accountInfo: pipe(
    account.account,
    loadable.toOption,
    option.flatten,
    option.getOrElse((): UAAAccount => emptyAccount),
    UAAAccount.encode
  ),
})

const HeaderStyled = styled(Layout.Header)`
  position: sticky;
  z-index: 99;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: bindActionCreators(logoutAction, dispatch),
})

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Header = ({ accountInfo, logout }: Props) => (
  <HeaderStyled>
    <Link to="/">
      <img src={logo} style={{ height: '2rem', display: 'inherit' }} />
    </Link>

    <Col xs={{ span: 6 }} lg={{ span: 2 }}>
      <Row justify="center">
        <Col span={12}>
          <AccountSettingsDropDown
            logoutAction={logout}
            accountInfo={accountInfo}
          />
        </Col>
        <Col span={12}>
          <OrganizationSettingsDropDown />
        </Col>
      </Row>
    </Col>
  </HeaderStyled>
)

export default connect(mapStateToProps, mapDispatchToProps)(Header)
