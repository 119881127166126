import { LeftOutlined } from '@ant-design/icons'
import { Alert, Button, Form, Input, Row, Typography } from 'antd'
import { goBack } from 'connected-react-router'
import { option, record } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../shared/translations/data'
import { loadable, prismEmail } from '../../../shared/types'
import { confirmInitRequest, updateInitRequest } from './types'

const mapStateToProps = ({
  account: { account },
  locale,
  resetPassword: { initRequest, initRequestResult },
}: State) => ({
  locale,
  loading: loadable.isLoading(account) || loadable.isLoading(initRequestResult),
  data: initRequest,
  error: pipe(
    initRequestResult,
    loadable.caseOf({ Err: (e) => option.some(e), _: () => option.none })
  ),
  success: loadable.isOk(initRequestResult),
  missing: pipe(
    initRequest,
    record.map((x) => x.value === '' && !x.pristine)
  ),
  invalidEmail:
    initRequest.email.value !== '' &&
    !initRequest.email.pristine &&
    option.isNone(prismEmail.getOption(initRequest.email.value)),
})

const mapDispatchToProps = { goBack, updateInitRequest, confirmInitRequest }

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
const Header = styled(Typography.Title)`
  text-align: center;
  margin-bottom: 24px;
`
const ResetForm = styled(Form)`
  & > .ant-alert {
    margin-bottom: 16px;
  }

  display: grid;
  grid-gap: 2px;
  overflow: hidden;
`

const Init = ({
  confirmInitRequest,
  data,
  invalidEmail,
  missing,
  loading,
  locale,
  goBack,
  error,
  success,
  updateInitRequest,
}: Props) => (
  <>
    <Header level={4}>
      <TranslatedMessage id="resetPassword" />
    </Header>
    <ResetForm
      onFinish={() => {
        return confirmInitRequest()
      }}
    >
      <Form.Item
        help={
          missing.email ? (
            <TranslatedMessage id="requiredEmail" />
          ) : invalidEmail ? (
            <TranslatedMessage id="invalidEmail" />
          ) : null
        }
        hasFeedback={missing.email}
        validateStatus={missing.email || invalidEmail ? 'error' : 'success'}
      >
        <Input
          value={data.email.value}
          placeholder={translatedMessage(locale)('email')}
          disabled={loading}
          onChange={(e) => updateInitRequest({ email: e.target.value })}
        />
      </Form.Item>
      {option.isSome(error) && (
        <Alert
          message={<TranslatedMessage id="resetRequestFailed" />}
          type="error"
        />
      )}
      {success && (
        <Alert
          message={<TranslatedMessage id="resetRequestSuccess" />}
          type="success"
        />
      )}
      <Row justify="space-between">
        <Button type="link" onClick={goBack}>
          <LeftOutlined style={{ color: '#51b148' }} />
          <TranslatedMessage id="cancel" />
        </Button>
        <Button
          type="primary"
          disabled={
            missing.email || loading || data.email.pristine || invalidEmail
          }
          htmlType="submit"
        >
          <TranslatedMessage id="resetPassword" />
        </Button>
      </Row>
    </ResetForm>
  </>
)

export default connect(mapStateToProps, mapDispatchToProps)(Init)
