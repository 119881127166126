import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'
import { connect } from 'react-redux'

import { State } from '../../../../shared/state/store'
import { translatedMessage } from '../../../../shared/translations/data'
import { loadable } from '../../../../shared/types'
import { deleteOrganizationUsers } from '../../types'

const mapStateToProps = ({
  accountSetting: { profileSettingTabNumber, organization },
  locale,
}: State) => ({
  messageReader: translatedMessage(locale),
  organizationName: pipe(
    organization,
    loadable.map((t) => t.name),
    loadable.getOrElse((): string => '')
  ),
  tabNumber: profileSettingTabNumber,
})
type OwnProps = {
  user: any
}

const mapDispatchToProps = {
  deleteOrganizationUsers: deleteOrganizationUsers,
}
type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  OwnProps

const UserDeleteModal = ({
  user,
  organizationName,
  deleteOrganizationUsers,
  messageReader,
}: Props) => {
  function error() {
    Modal.confirm({
      title: `Remove User ${user.user}`,
      content: (
        <>
          {messageReader('SelectUserModalText')}
          <br />
          {messageReader('SelectUserModalText2')}
        </>
      ),
      icon: <CloseCircleOutlined style={{ color: 'red' }} />,

      okText: messageReader('confirmation'),
      okType: 'danger',
      cancelText: messageReader('cancel'),
      onOk() {
        deleteOrganizationUsers({ organizationName, userId: user?.id })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  return <Button danger icon={<DeleteOutlined />} onClick={error} />
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDeleteModal)
