import {
  CreditCardOutlined,
  FundFilled,
  SettingFilled,
  ShopOutlined,
  WalletFilled,
} from '@ant-design/icons'
import { Card, Col, Row, Switch, Typography } from 'antd'
import { array } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'

import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import { TranslatedMessage } from '../../../shared/translations/data'
import { loadable } from '../../../shared/types'
import {
  applications,
  emptyOrganization,
  Organization,
  updateOrganizationModulesAction,
} from './../types'

const Icon = styled.div.attrs((props) => ({
  // or we can define dynamic ones
  color: props.color || '#666666',
}))`
  height: 80px;
  width: 80px;

  background: ${(props) => props.color};
  border-radius: 40px;
  margin: auto;
  svg {
    height: 40px;
    width: 40px;
    color: #fff;
    margin-left: 20px;
    margin-top: 20px;
  }
`

const mapStateToProps = ({ accountSetting: { organization } }: State) => {
  const currentOrganization = pipe(
    organization,
    loadable.getOrElse((): Organization => emptyOrganization)
  )

  const permissions = currentOrganization.modules

  return {
    organization: currentOrganization,
    permissions,
  }
}
type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
const mapDispatchToProps = {
  updateOrganizationModulesAction: updateOrganizationModulesAction,
}

const OrganizationModules = ({
  organization,
  permissions,
  updateOrganizationModulesAction,
}: Props) => {
  const images = (color: string) => {
    return {
      WALLET: (
        <Icon color={color}>
          <WalletFilled />
        </Icon>
      ),
      BILLS: (
        <Icon color={color}>
          <CreditCardOutlined />
        </Icon>
      ),
      MARKETPLACE: (
        <Icon color={color}>
          <ShopOutlined />
        </Icon>
      ),
      DASHBOARD: (
        <Icon color={color}>
          <FundFilled />
        </Icon>
      ),
      BACKOFFICE: (
        <Icon color={color}>
          <SettingFilled />
        </Icon>
      ),
    }
  }

  function onChange(moduleName: string, checked: any) {
    let per = permissions

    if (checked) {
      per.push(moduleName)
      per = [...new Set(per)]
    } else {
      per = per.filter((e) => e !== moduleName)
    }
    const newOrganization = organization
    newOrganization.modules = per
    updateOrganizationModulesAction(newOrganization)
  }
  return (
    <>
      <Row gutter={[16, 16]}>
        {pipe(
          applications,
          array.map((app) => (
            <Col lg={12} md={24} sm={24} key={app.name}>
              <Card>
                <Row>
                  <Col span={8}>
                    {
                      images(
                        permissions.includes(app.name) ? '#51b148' : '#333333'
                      )[app.name]
                    }
                  </Col>
                  <Col span={16}>
                    <Row justify="space-between">
                      <Col>
                        <Typography.Title level={4} type="secondary">
                          {app.name}
                        </Typography.Title>
                      </Col>
                      <Col>
                        <Switch
                          checked={permissions.includes(app.name)}
                          onChange={(checked) => onChange(app.name, checked)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <TranslatedMessage id={app.descriptionKey} />
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))
        )}
      </Row>
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationModules)
