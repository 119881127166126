import Icon from '@ant-design/icons'
import { Alert, Button, Card, Col, Form, Input, Row, Typography } from 'antd'
import { goBack } from 'connected-react-router'
import { option, record } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../shared/translations/data'
import { loadable, prismEmail } from '../../../shared/types'
import { requestTFAAction, updateEmailAction } from './types'

const mapStateToProps = ({
  account: { account },
  locale,
  tfaRequest: { requestResult, userEmail },
}: State) => ({
  locale,
  loading: loadable.isLoading(account) || loadable.isLoading(requestResult),
  data: userEmail,
  error: pipe(
    requestResult,
    loadable.caseOf({ Err: (e) => option.some(e), _: () => option.none })
  ),
  success: loadable.isOk(requestResult),
  missing: pipe(
    userEmail,
    record.map((x) => x.value === '' && !x.pristine)
  ),
  invalidEmail:
    userEmail.email.value !== '' &&
    !userEmail.email.pristine &&
    option.isNone(prismEmail.getOption(userEmail.email.value)),
})

const mapDispatchToProps = {
  goBack,
  updateEmail: (e: React.ChangeEvent<HTMLInputElement>) =>
    updateEmailAction({
      email: e.target.value,
    }),
  confirm: requestTFAAction,
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const EmailForm = styled(Form)`
  & > .ant-alert {
    margin-bottom: 16px;
  }
`

const TFAEmail = ({
  loading,
  locale,
  goBack,
  updateEmail,
  confirm,
  missing,
  invalidEmail,
  data,
  error,
  success,
}: Props) => (
  <>
    <Row gutter={[8, 32]}>
      <Col span={24}>
        <Card hoverable>
          <Typography.Title level={4}>
            <TranslatedMessage id="resendTFACode" />
          </Typography.Title>
          <EmailForm onFinish={confirm}>
            <Form.Item
              help={
                missing.email ? (
                  <TranslatedMessage id="requiredEmail" />
                ) : invalidEmail ? (
                  <TranslatedMessage id="invalidEmail" />
                ) : null
              }
              hasFeedback={missing.email || invalidEmail}
              validateStatus={
                missing.email || invalidEmail ? 'error' : 'success'
              }
            >
              <Input
                value={data.email.value}
                placeholder={translatedMessage(locale)('email')}
                disabled={loading}
                onChange={updateEmail}
              />
            </Form.Item>
            {option.isSome(error) && (
              <Alert
                message={<TranslatedMessage id="tfaRequestFailed" />}
                type="error"
              />
            )}
            {success && (
              <Alert
                message={<TranslatedMessage id="tfaRequestSuccess" />}
                type="success"
              />
            )}
            <Row justify="space-between">
              <Button type="link" onClick={goBack}>
                <Icon type="left" />
                <TranslatedMessage id="cancel" />
              </Button>
              <Button
                type="primary"
                disabled={
                  missing.email ||
                  loading ||
                  data.email.pristine ||
                  invalidEmail
                }
                htmlType="submit"
              >
                <TranslatedMessage id="resend" />
              </Button>
            </Row>
          </EmailForm>
        </Card>
      </Col>
    </Row>
  </>
)

export default connect(mapStateToProps, mapDispatchToProps)(TFAEmail)
