import {
  CreditCardOutlined,
  FundFilled,
  SettingFilled,
  ShopOutlined,
  WalletFilled,
} from '@ant-design/icons'
import { Alert, Card, Col, Divider, Row, Typography } from 'antd'
import { array, option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../shared/state/store'
import { TranslatedMessage } from '../../shared/translations/data'
import { loadable } from '../../shared/types'
import { emptyAccount, UAAAccount } from '../Authentication/Login'
import { applications, emptyOrganization, Organization } from './types'

const { Meta } = Card
const CardContainer = styled(Card)`
  padding-top: 24px;
  height: 100%;
  width: 380px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1);
`

const Icon = styled.div`
  height: 160px;
  width: 165px;
  background: #51b148;
  border-radius: 80px;
  margin: auto;
  svg {
    height: 80px;
    width: 80px;
    color: #fff;
    margin-left: 40px;
    margin-top: 40px;
  }
`
const images = {
  WALLET: (
    <Icon>
      <WalletFilled />
    </Icon>
  ),
  BILLS: (
    <Icon>
      <CreditCardOutlined />
    </Icon>
  ),
  MARKETPLACE: (
    <Icon>
      <ShopOutlined />
    </Icon>
  ),
  DASHBOARD: (
    <Icon>
      <FundFilled />
    </Icon>
  ),
  BACKOFFICE: (
    <Icon>
      <SettingFilled />
    </Icon>
  ),
}
const MetaContainer = styled(Meta)`
  display: block;
  text-align: center;
`

const ColContainer = styled(Col)`
  &:hover {
    ${CardContainer} {
      height: 100%;
      box-shadow: 5px 8px 24px 5px rgba(81, 177, 72, 0.5);
    }
  }
`

const mapStateToProps = ({
  account,
  accountSetting: { organization },
}: State) => {
  const a = pipe(
    account.account,
    loadable.toOption,
    option.flatten,
    option.getOrElse((): UAAAccount => emptyAccount),
    UAAAccount.encode
  )
  const currentOrganization = pipe(
    organization,
    loadable.getOrElse((): Organization => emptyOrganization)
  )
  const organizationInfo = a.organizations.find(
    (organization) => organization.name === currentOrganization.name
  )

  const permissions =
    organizationInfo && organizationInfo?.permissions
      ? Object.entries(organizationInfo?.permissions).filter(
          ([_, permission]) => permission !== 'NONE'
        )
      : []

  return {
    isOrganizationLoaded: loadable.isOk(organization),
    applications: pipe(
      applications,
      array.filter((app) =>
        permissions.map(([modules, _]) => modules).includes(app.name)
      )
    ),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
const AccountApps = ({ applications }: StateProps) => {
  return (
    <>
      <Row justify="center" align="middle">
        <Typography.Title level={2}>
          <TranslatedMessage id="services" />
        </Typography.Title>
      </Row>

      {applications.length === 0 ? (
        <>
          <Alert
            message={
              <TranslatedMessage
                id={'No-Authority'}
                defaultMessage="No Authority"
              />
            }
            description={
              <TranslatedMessage
                id="NoModulePermissions"
                defaultMessage="Your Account doesn't have the permission to access any module please contact admin"
              />
            }
            type="warning"
          />
        </>
      ) : (
        <Row gutter={[48, 16]} justify="center">
          <Divider />
          {applications.map((app) => (
            <ColContainer key={app.name}>
              <a href={app.href}>
                <CardContainer
                  hoverable
                  onClick={() => {
                    window.location.href = app.href
                  }}
                  bordered={false}
                  cover={images[app.icon]}
                >
                  <Divider></Divider>

                  <MetaContainer
                    title={
                      <Typography.Title level={4} type="secondary">
                        {app.name}
                      </Typography.Title>
                    }
                    description={<TranslatedMessage id={app.descriptionKey} />}
                  />
                </CardContainer>
              </a>
            </ColContainer>
          ))}
          <Divider />
        </Row>
      )}
    </>
  )
}

export default connect(mapStateToProps)(AccountApps)
