import * as t from './types'
import * as effects from 'redux-saga/effects'
import * as api from './api'
import {
  NotRequested,
  extractFormData,
  foldEitherToAction,
  Loading,
  result,
  getFormDataFromPartialForm,
  prismEmail,
  prismPassword,
} from '../../../shared/types'
import { pipe } from 'fp-ts/lib/pipeable'
import { option, task } from 'fp-ts'
import { lens } from 'lens.ts'
import { CancelToken } from 'axios'
import { State } from '../../../shared/state/store'
import { takeLatest } from '../../../shared/state/saga'
import { push } from 'connected-react-router'

export const initialState: t.State = {
  initRequest: { email: { value: '', pristine: true } },
  finishRequest: {
    key: { value: '', pristine: true },
    newPassword: { value: '', pristine: true },
    confirmPassword: { value: '', pristine: true },
  },
  initRequestResult: NotRequested,
  finishRequestResult: NotRequested,
}

const _l = lens<t.State>()

export const reducer = (s = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'RESET/update_init_request':
      return pipe(
        s,
        _l.initRequest.set((l) => ({
          ...l,
          ...getFormDataFromPartialForm(a.payload),
        }))
      )
    case 'RESET/update_finish_request':
      return pipe(
        s,
        _l.finishRequest.set((l) => ({
          ...l,
          ...getFormDataFromPartialForm(a.payload),
        }))
      )
    case 'RESET/confirm_init_request':
      return pipe(s, _l.initRequestResult.set(Loading))
    case 'RESET/init_request_result':
      return pipe(
        s,
        _l.initRequestResult.set(a.payload),
        _l.initRequest.set((r) =>
          result.isOk(a.payload) ? initialState.initRequest : r
        )
      )
    case 'RESET/confirm_finish_request':
      return pipe(
        s,
        _l.finishRequestResult.set((p) =>
          pipe(
            s.finishRequest,
            extractFormData,
            option.chain((v) => prismPassword.getOption(v.newPassword)),
            option.map(() => Loading),
            option.getOrElse(() => p)
          )
        )
      )
    case 'RESET/finish_request_result':
      return pipe(
        s,
        _l.finishRequestResult.set(a.payload),
        _l.finishRequest.set((r) =>
          result.isOk(a.payload) ? initialState.finishRequest : r
        )
      )
    case '@@router/LOCATION_CHANGE':
      return pipe(
        new URLSearchParams(a.payload.location.search).get('key'),
        option.fromNullable,
        option.map((value) =>
          _l.finishRequest.key.set({ value, pristine: false })(initialState)
        ),
        option.getOrElse(() => initialState)
      )
    default:
      return s
  }
}

function* confirmInitSaga(_: t.ConfirmInitRequest, token: CancelToken) {
  const req: State['resetPassword']['initRequest'] = yield effects.select(
    (s: State) => s.resetPassword.initRequest
  )
  const email = pipe(
    req,
    extractFormData,
    option.chain((v) => prismEmail.getOption(v.email))
  )
  if (option.isNone(email)) {
    return
  }
  yield yield effects.call(
    pipe(
      api.resetPassword(email.value, token),
      task.map(foldEitherToAction(t.initRequestResult)),
      task.map((a) => effects.put(a))
    )
  )
}

function* confirmFinishSaga(_: t.ConfirmFinishRequest, token: CancelToken) {
  const req: State['resetPassword']['finishRequest'] = yield effects.select(
    (s: State) => s.resetPassword.finishRequest
  )
  const data = extractFormData(req)
  if (
    option.isNone(data) ||
    option.isNone(prismPassword.getOption(data.value.newPassword))
  ) {
    return
  }
  yield yield pipe(
    api.confirmResetPassword(data.value, token),
    task.map(foldEitherToAction(t.finishRequestResult)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  )
}

function* resetRequestSaga(a: t.FinishRequestResult) {
  if (result.isErr(a.payload)) {
    return
  }
  yield effects.delay(5000)
  yield effects.put(push('/'))
}

export function* saga() {
  yield effects.all([
    takeLatest('RESET/confirm_init_request', confirmInitSaga),
    takeLatest('RESET/confirm_finish_request', confirmFinishSaga),
    takeLatest('RESET/finish_request_result', resetRequestSaga),
  ])
}
