import {
  CreditCardOutlined,
  FundFilled,
  SettingFilled,
  ShopOutlined,
  UserAddOutlined,
  WalletFilled,
} from '@ant-design/icons'
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Switch,
} from 'antd'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { State } from '../../../../shared/state/store'
import { applications, postOrganizationUsers } from '../../types'
import { loadable } from '../../../../shared/types'
import { emptyOrganization, Organization } from '../..'

const mapStateToProps = ({ accountSetting: { organization } }: State) => {
  const currentOrganization = pipe(
    organization,
    loadable.getOrElse((): Organization => emptyOrganization)
  )

  return {
    permissions: currentOrganization.modules,
    organization: currentOrganization,
  }
}

const images = {
  WALLET: <WalletFilled />,
  BILLS: <CreditCardOutlined />,
  MARKETPLACE: <ShopOutlined />,
  DASHBOARD: <FundFilled />,
  BACKOFFICE: <SettingFilled />,
}

const mapDispatchToProps = {
  postOrganizationUsers: postOrganizationUsers,
}
type OwnProps = {
  organizationName: string
}
type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  OwnProps

const UserAddModal = ({
  organizationName,
  permissions,
  postOrganizationUsers,
}: Props) => {
  const [visible, setVisible] = React.useState(false)
  const [form] = Form.useForm()

  return (
    <>
      <Button
        icon={<UserAddOutlined />}
        onClick={() => {
          setVisible(true)
        }}
      >
        <FormattedMessage id="AddUser" defaultMessage="Add User" />
      </Button>

      <Modal
        width={700}
        title={
          <FormattedMessage
            id="AddOrganizationUser"
            defaultMessage="Add Organization User"
          />
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            form="userAddForm"
            key="close"
            onClick={() => setVisible(false)}
          >
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>,
          <Button
            type="primary"
            form="userAddForm"
            key="submit"
            htmlType="submit"
          >
            <FormattedMessage id="ADD" defaultMessage="Add" />
          </Button>,
        ]}
      >
        <Form
          id="userAddForm"
          form={form}
          size="small"
          labelAlign="left"
          labelCol={{ span: 8 }}
          onFinish={(values) => {
            const addUser = {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              organizations: [
                {
                  name: organizationName,

                  permissions: {
                    DASHBOARD: values.DASHBOARD ? values.DASHBOARD : 'NONE',
                    BACKOFFICE: values.BACKOFFICE ? values.BACKOFFICE : 'NONE',
                    WALLET: values.WALLET ? values.WALLET : 'NONE',
                    MARKETPLACE: values.MARKETPLACE
                      ? values.MARKETPLACE
                      : 'NONE',
                    BILLS: values.BILLS ? values.BILLS : 'NONE',
                  },
                  authorities: values.admin
                    ? 'ORGANIZATION_ADMIN'
                    : 'ORGANIZATION_USER',
                },
              ],
              login: `${values.firstName}${values.lastName}`.replace(/\s/g, ''),
            }

            postOrganizationUsers({ organizationName, user: addUser })

            setVisible(false)
          }}
        >
          <Form.Item
            label={<FormattedMessage id="email" defaultMessage="Email" />}
            name="email"
            required
            rules={[
              {
                type: 'email',
                required: true,
                message: (
                  <FormattedMessage
                    id="missingEmailMsg"
                    defaultMessage="Please input your Email!"
                  />
                ),
              },
            ]}
            tooltip="This is a required field"
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage id="fullName" defaultMessage="Full Name" />
            }
            required
            tooltip="This is a required field"
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="missingFullNameMsg"
                    defaultMessage="Please input your Full Name!"
                  />
                ),
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <Row justify="space-between">
              <Col span={11}>
                <Form.Item
                  tooltip="This is a required field"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="missingFirstnameMsg"
                          defaultMessage="Please input your First Name!"
                        />
                      ),
                    },
                  ]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  tooltip="This is a required field"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="missingLastnameMsg"
                          defaultMessage="Please input your Last Name!"
                        />
                      ),
                    },
                  ]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage id="Permissions" defaultMessage="Permissions" />
            }
            labelCol={{ span: 24 }}
          >
            {applications.map((app) => (
              <>
                {permissions.includes(app.name) ? (
                  <Row key={app.descriptionKey} justify="end">
                    <Col span={2}>
                      <Avatar icon={images[app.name]} />
                    </Col>
                    <Col span={6}> {app.name}</Col>
                    <Col span={14}>
                      <Form.Item name={app.name}>
                        <Radio.Group>
                          <Radio.Button value="CONTRIBUTOR">
                            <FormattedMessage
                              id="Contributor"
                              defaultMessage="Contributor"
                            />
                          </Radio.Button>
                          <Radio.Button value="VIEWER">
                            <FormattedMessage
                              id="Viewer"
                              defaultMessage="Viewer"
                            />
                          </Radio.Button>
                          <Radio.Button value="NONE">
                            <FormattedMessage id="None" defaultMessage="None" />
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
              </>
            ))}
          </Form.Item>
          <Form.Item
            name="admin"
            label={
              <FormattedMessage id="ROLE_ADMIN" defaultMessage="ROLE_ADMIN" />
            }
            valuePropName="checked"
          >
            <Switch checked={false} size="default" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAddModal)
