import { AppstoreOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Card, Col, Modal, Row, Typography } from 'antd'
import { option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import React from 'react'
import { connect } from 'react-redux'

import {
  closeAccountsModal,
  getOrganizationAction,
  getOrganizationUsers,
} from '../../../components/Account'
import {
  emptyAccount,
  UAAAccount,
} from '../../../components/Authentication/Login'
import { State } from '../../state/store'
import { TranslatedMessage } from '../../translations/data'
import { loadable } from '../../types'

const mapStateToProps = ({ account, accountSetting }: State) => ({
  isAccountModalOpen:
    (accountSetting.isAccountsModalOpen &&
      loadable.isErr(accountSetting.organization)) ||
    loadable.isNotRequested(accountSetting.organization),

  accountInfo: pipe(
    account.account,
    loadable.toOption,
    option.flatten,
    option.getOrElse((): UAAAccount => emptyAccount),
    UAAAccount.encode
  ),
})
const mapDispatchToProps = {
  closeAccountsModal: closeAccountsModal,
  getOrganizationAction: getOrganizationAction,
  getOrganizationUsers: getOrganizationUsers,
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const WorkSpaceSelectModal = ({
  accountInfo,
  isAccountModalOpen,
  closeAccountsModal,
  getOrganizationAction,
  getOrganizationUsers,
}: Props) => {
  const handleOk = (organizationName: string) => {
    getOrganizationUsers(organizationName)
    getOrganizationAction(organizationName)
    closeAccountsModal()
  }

  return (
    <>
      <Modal
        width={700}
        centered
        title="ProsperUs Accounts"
        visible={isAccountModalOpen}
        closable={false}
        footer={null}
      >
        {accountInfo.organizations.map((organization) => (
          <React.Fragment key={organization.name}>
            {organization.name !== 'PROSPERUS' ? (
              <Row key={organization.name} gutter={[48, 16]}>
                <Col span={24}>
                  <Card
                    hoverable
                    style={{ textAlign: 'center' }}
                    onClick={() => handleOk(organization.name)}
                  >
                    <Row align="middle">
                      <Col span={8}>
                        <Avatar size="large" icon={<AppstoreOutlined />} />
                      </Col>
                      <Col span={12}>
                        <Typography.Title level={3} type="secondary">
                          {organization.name} Account
                        </Typography.Title>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            ) : null}
          </React.Fragment>
        ))}
        {accountInfo.organizations.map((organization) => (
          <React.Fragment key={organization.name}>
            {organization.name === 'PROSPERUS' ? (
              <Row key={organization.name} gutter={[48, 16]}>
                <Col span={24}>
                  <Card
                    hoverable
                    style={{ textAlign: 'center' }}
                    onClick={() => handleOk(organization.name)}
                  >
                    <Row align="middle">
                      <Col span={8}>
                        <Avatar size="large" icon={<UserOutlined />} />
                      </Col>
                      <Col span={12}>
                        <Typography.Title level={3} type="secondary">
                          <TranslatedMessage id={'PersonalAccount'} />
                        </Typography.Title>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            ) : null}
          </React.Fragment>
        ))}
      </Modal>
    </>
  )
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkSpaceSelectModal)
