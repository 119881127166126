import * as React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'
import { pipe } from 'fp-ts/lib/pipeable'
import { Spin } from 'antd'
import { option } from 'fp-ts'
import { State } from '../state/store'
import { loadable } from '../types'
import styled from 'styled-components'
const FullPageSpin = styled(Spin)`
  height: calc(100% - ${(props) => props.theme.footerHeight}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const mapStateToProps = ({ account: { account, redirect } }: State) => ({
  isLoggedOut: pipe(
    account,
    loadable.caseOf({ Ok: option.isNone, Loading: () => false, _: () => true })
  ),
  isLoading:
    loadable.isLoading(account) ||
    loadable.isNotRequested(account) ||
    option.isSome(redirect),
})

type Props = RouteProps & ReturnType<typeof mapStateToProps>

const PrivateRoute = ({ isLoading, isLoggedOut, ...rest }: Props) =>
  isLoggedOut ? (
    <Redirect to="/auth/login" />
  ) : isLoading ? (
    <FullPageSpin tip="Loading" />
  ) : (
    <Route {...rest} />
  )

export default connect(mapStateToProps)(PrivateRoute)
