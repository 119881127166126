import { Alert, Button, Form, Input, Typography } from 'antd'
import { option, record } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../shared/translations/data'
import { loadable, prismPassword } from '../../../shared/types'
import { confirmFinishRequest, updateFinishRequest } from './types'

const mapStateToProps = ({
  account: { account },
  locale,
  resetPassword: { finishRequest, finishRequestResult },
}: State) => ({
  locale,
  loading:
    loadable.isLoading(account) || loadable.isLoading(finishRequestResult),
  data: finishRequest,
  error: pipe(
    finishRequestResult,
    loadable.caseOf({ Err: (e) => option.some(e), _: () => option.none })
  ),
  success: loadable.isOk(finishRequestResult),
  missing: pipe(
    finishRequest,
    record.map((x) => x.value === '' && !x.pristine)
  ),
  invalidPassword:
    !finishRequest.newPassword.pristine &&
    option.isNone(prismPassword.getOption(finishRequest.newPassword.value)),
})

const mapDispatchToProps = { updateFinishRequest, confirmFinishRequest }

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const ResetForm = styled(Form)`
  & > .ant-alert {
    margin-bottom: 16px;
  }
  display: grid;
  grid-gap: 8px;
  overflow: hidden;
`
const SubmitButton = styled(Button)`
  width: 100%;
  margin-bottom: 24px;
  margin-top: 0px;
`

const SetPassword = ({
  confirmFinishRequest,
  data,
  missing,
  loading,
  locale,
  error,
  success,
  updateFinishRequest,
  invalidPassword,
}: Props) => (
  <>
    <Typography.Title level={4}>
      <TranslatedMessage id="addPassword" />
    </Typography.Title>
    <ResetForm onFinish={confirmFinishRequest}>
      <Form.Item
        help={
          missing.newPassword ? (
            <TranslatedMessage id="requiredPassword" />
          ) : invalidPassword ? (
            <TranslatedMessage id="invalidPassword" />
          ) : null
        }
        hasFeedback={missing.newPassword || invalidPassword}
        validateStatus={
          missing.newPassword || invalidPassword ? 'error' : 'success'
        }
      >
        {!success && (
          <Input
            value={data.newPassword.value}
            placeholder={translatedMessage(locale)('password')}
            disabled={loading}
            onChange={(e) =>
              updateFinishRequest({ newPassword: e.target.value })
            }
            type="password"
          />
        )}
      </Form.Item>

      <Form.Item
        help={
          missing.confirmPassword ? (
            <TranslatedMessage id="requiredPassword" />
          ) : data.confirmPassword.value !== data.newPassword.value ? (
            <TranslatedMessage id="mismatchedPasswords" />
          ) : (
            <TranslatedMessage id="setPasswordDescription" />
          )
        }
        hasFeedback={
          missing.confirmPassword ||
          data.confirmPassword.value !== data.newPassword.value
        }
        validateStatus={
          missing.newPassword ||
          data.confirmPassword.value !== data.newPassword.value
            ? 'error'
            : 'success'
        }
      >
        {!success && (
          <Input
            value={data.confirmPassword.value}
            placeholder={translatedMessage(locale)('confirmPassword')}
            disabled={loading}
            onChange={(e) =>
              updateFinishRequest({ confirmPassword: e.target.value })
            }
            type="password"
          />
        )}
      </Form.Item>
      {option.isSome(error) && (
        <Alert
          message={<TranslatedMessage id="finishSetFailed" />}
          type="info"
        />
      )}
      {success && (
        <Alert
          message={<TranslatedMessage id="finishSetSuccess" />}
          type="success"
        />
      )}

      <SubmitButton
        type="primary"
        disabled={
          missing.newPassword ||
          loading ||
          data.newPassword.pristine ||
          invalidPassword ||
          data.confirmPassword.value !== data.newPassword.value
        }
        htmlType="submit"
      >
        <TranslatedMessage id="setPassword" />
      </SubmitButton>
    </ResetForm>
  </>
)

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword)
