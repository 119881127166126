import { CancelToken } from 'axios'
import { array, either, option, task } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import { lens } from 'lens.ts'
import * as effects from 'redux-saga/effects'

import { takeLatest } from '../../shared/state/saga'
import { State } from '../../shared/state/store'
import {
  foldEitherToAction,
  loadable,
  Loading,
  NotRequested,
  result,
} from '../../shared/types'
import { getAccountAction } from '../Authentication/Login/types'
import * as api from './api'
import * as t from './types'

export const initialState: t.State = {
  updateResult: NotRequested,
  changePasswordForm: option.none,
  changePasswordResult: NotRequested,
  organization: NotRequested,
  organizationUsers: NotRequested,

  uploadImageResult: NotRequested,
  profileSettingTabNumber: '1',
  selectedFile: option.none,
  isAccountsModalOpen: false,
}

const _l = lens<t.State>()

export const reducer = (s = initialState, a: t.Actions): t.State => {
  switch (a.type) {
    case 'ACCOUNT/updateProfileTab':
      return pipe(s, _l.profileSettingTabNumber.set(a.payload))
    case 'ACCOUNT/update_selected_file':
      return pipe(s, _l.selectedFile.set(option.some(a.payload)))
    case 'ACCOUNT/OPEN_ACCOUNT_MODAL':
      return pipe(s, _l.isAccountsModalOpen.set(true))
    case 'ACCOUNT/CLOSE_ACCOUNT_MODAL':
      return pipe(s, _l.isAccountsModalOpen.set(false))
    case 'ACCOUNT/update':
      return pipe(s, _l.updateResult.set(Loading))
    case 'ACCOUNT/update_result':
      return pipe(s, _l.updateResult.set(a.payload))
    case 'ACCOUNT/change_password':
      return pipe(s, _l.changePasswordResult.set(Loading))
    case 'ACCOUNT/change_password_result':
      return pipe(s, _l.changePasswordResult.set(a.payload))
    case 'ACCOUNT/update_organization':
      return pipe(s, _l.organization.set(Loading))
    case 'ACCOUNT/update_organization_result':
      return pipe(s, _l.organization.set(a.payload))
    case 'ACCOUNT/update_organization_modules':
      return pipe(s, _l.organization.set(Loading))
    case 'ACCOUNT/update_organization_modules_result':
      return pipe(s, _l.organization.set(a.payload))
    case 'ACCOUNT/GET_ORGANIZATION':
      return pipe(s, _l.organization.set(Loading))
    case 'ACCOUNT/GET_ORGANIZATION_RESULT':
      return pipe(s, _l.organization.set(a.payload))
    case 'ACCOUNT/GET_ORGANIZATION_USERS':
      return pipe(s, _l.organizationUsers.set(Loading))
    case 'ACCOUNT/GET_ORGANIZATION_USERS_RESULT':
      return pipe(s, _l.organizationUsers.set(a.payload))
    case 'ACCOUNT/POST_ORGANIZATION_USERS':
      return pipe(s, _l.organizationUsers.set(Loading))
    case 'ACCOUNT/POST_ORGANIZATION_USERS_RESULT':
      return pipe(s, _l.organizationUsers.set(a.payload))
    case 'ACCOUNT/PUT_ORGANIZATION_USERS':
      return pipe(s, _l.organizationUsers.set(Loading))
    case 'ACCOUNT/PUT_ORGANIZATION_USERS_RESULT':
      return pipe(s, _l.organizationUsers.set(a.payload))
    case 'ACCOUNT/DELETE_ORGANIZATION_USERS':
      return pipe(s, _l.organizationUsers.set(Loading))
    case 'ACCOUNT/DELETE_ORGANIZATION_USERS_RESULT':
      return pipe(s, _l.organizationUsers.set(a.payload))

    case 'ACCOUNT/upload_image':
      return pipe(s, _l.uploadImageResult.set(Loading))
    case 'ACCOUNT/upload_image_result':
      return pipe(
        s,
        _l.uploadImageResult.set(a.payload),
        _l.selectedFile.set((f) =>
          pipe(
            a.payload,
            result.caseOf({
              Err: () => f,
              Ok: (): option.Option<File> => option.none,
            })
          )
        )
      )
    case '@@router/LOCATION_CHANGE':
      return pipe(s, _l.updateResult.set(initialState.updateResult))
    default:
      return s
  }
}

function* updateSaga(a: t.UpdateAccountAction, token: CancelToken) {
  const previous: State['account']['account'] = yield effects.select(
    (s: State) => s.account.account
  )

  const account = pipe(previous, loadable.toOption, option.flatten)
  if (option.isNone(account)) {
    return
  }
  const accountUpdate = { ...account.value, ...a.payload }

  yield yield pipe(
    api.updateAccount(accountUpdate, token),
    task.map(foldEitherToAction(t.updateAccountResult)),
    task.map((a) => effects.put(a)),
    (e) => effects.call(e)
  )
}

function* updateOrganizationSaga(
  a: t.UpdateOrganizationAction,
  token: CancelToken
) {
  yield yield effects.call(
    pipe(
      api.updateOrganization(a.payload, token),
      task.map(foldEitherToAction(t.updateOrganizationResultAction)),
      task.map((a) => effects.put(a))
    )
  )
}
function* updateOrganizationModulesSaga(
  a: t.UpdateOrganizationModulesAction,
  token: CancelToken
) {
  yield yield effects.call(
    pipe(
      api.updateOrganizationModules(a.payload, token),
      task.map(foldEitherToAction(t.updateOrganizationModulesResultAction)),
      task.map((a) => effects.put(a))
    )
  )
}
function* getOrganizationSaga(a: t.GetOrganizationAction, token: CancelToken) {
  yield yield effects.call(
    pipe(
      api.getOrganizations(a.payload, token),
      task.map(
        either.fold(
          (e) => [t.getOrganizationResultAction.err(e)],
          (arr) => {
            return [t.getOrganizationResultAction.ok(arr)]
          }
        )
      ),
      task.map(array.map((a) => effects.put(a))),
      task.map((ps) => effects.all(ps))
    )
  )
}
function* getOrganizationUserSaga(
  a: t.GetOrganizationUsers,
  token: CancelToken
) {
  yield yield effects.call(
    pipe(
      api.getOrganizationsUsers(a.payload, token),
      task.map(foldEitherToAction(t.getOrganizationUsersResultAction)),
      task.map((a) => effects.put(a))
    )
  )
}
function* postOrganizationUserSaga(
  a: t.PostOrganizationUsers,
  token: CancelToken
) {
  yield yield effects.call(
    pipe(
      api.postOrganizationsUsers(
        a.payload.organizationName,
        a.payload.user,
        token
      ),

      task.map(
        either.fold(
          (e) => [
            t.postOrganizationUsersResultAction.err(e),
            t.getOrganizationUsers(a.payload.organizationName),
          ],
          (arr) => [t.postOrganizationUsersResultAction.ok(arr)]
        )
      ),
      task.map(array.map((a) => effects.put(a))),
      task.map((ps) => effects.all(ps))
    )
  )
}
function* putOrganizationUserSaga(
  a: t.PutOrganizationUsers,
  token: CancelToken
) {
  yield yield effects.call(
    pipe(
      api.putOrganizationsUsers(
        a.payload.organizationName,
        a.payload.user,
        token
      ),

      task.map(
        either.fold(
          (e) => [
            t.putOrganizationUsersResultAction.err(e),
            t.getOrganizationUsers(a.payload.organizationName),
          ],
          (arr) => [t.putOrganizationUsersResultAction.ok(arr)]
        )
      ),
      task.map(array.map((a) => effects.put(a))),
      task.map((ps) => effects.all(ps))
    )
  )
}
function* deleteOrganizationUserSaga(
  a: t.DeleteOrganizationUsers,
  token: CancelToken
) {
  yield yield effects.call(
    pipe(
      api.deleteOrganizationsUsers(
        a.payload.organizationName,
        a.payload.userId,
        token
      ),

      task.map(
        either.fold(
          (e) => [
            t.deleteOrganizationUsersResultAction.err(e),
            t.getOrganizationUsers(a.payload.organizationName),
          ],
          (arr) => [t.deleteOrganizationUsersResultAction.ok(arr)]
        )
      ),
      task.map(array.map((a) => effects.put(a))),
      task.map((ps) => effects.all(ps))
    )
  )
}
function* changePasswordSaga(a: t.ChangePasswordAction, token: CancelToken) {
  yield yield effects.call(
    pipe(
      api.changePassword(a.payload, token),
      task.map(foldEitherToAction(t.changePasswordResult)),
      task.map((a) => effects.put(a))
    )
  )
}

function* uploadFileSaga(a: t.UploadImageAction, token: CancelToken) {
  yield yield pipe(
    api.uploadFile(a.payload.file, token),
    task.map(
      either.fold(
        (e) => [t.uploadImageResultAction.err(e)],
        () => [t.uploadImageResultAction.ok(undefined), getAccountAction()]
      )
    ),
    task.map(array.map((a) => effects.put(a))),
    task.map((ps) => effects.all(ps)),
    (e) => effects.call(e)
  )
}

export function* saga() {
  yield effects.all([
    takeLatest('ACCOUNT/update', updateSaga),
    takeLatest('ACCOUNT/change_password', changePasswordSaga),
    takeLatest('ACCOUNT/update_organization', updateOrganizationSaga),
    takeLatest('ACCOUNT/GET_ORGANIZATION_USERS', getOrganizationUserSaga),
    takeLatest('ACCOUNT/POST_ORGANIZATION_USERS', postOrganizationUserSaga),
    takeLatest('ACCOUNT/PUT_ORGANIZATION_USERS', putOrganizationUserSaga),
    takeLatest('ACCOUNT/DELETE_ORGANIZATION_USERS', deleteOrganizationUserSaga),
    takeLatest('ACCOUNT/GET_ORGANIZATION', getOrganizationSaga),
    takeLatest('ACCOUNT/upload_image', uploadFileSaga),
    takeLatest(
      'ACCOUNT/update_organization_modules',
      updateOrganizationModulesSaga
    ),
  ])
}
