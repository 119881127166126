import { either, option, record, task } from 'fp-ts'
import { flow } from 'fp-ts/lib/function'
import { pipe } from 'fp-ts/lib/pipeable'
import * as t from 'io-ts'

import * as axios from '../../../axios'
import {
  GoogleLogin,
  Login,
  LoginStatus,
  OrganizationSignUpWithModules,
  SignUp,
  UAAAccount,
  VerifyEmailExitResult,
} from './types'

const LoginError = t.type({
  /* eslint-disable @typescript-eslint/naming-convention */
  error: t.literal('invalid_request'),
  error_description: t.keyof({
    'Missing two factor authentication code': null,
    'Invalid two factor authentication code': null,
    'Bad credentials': null,
  }),
  /* eslint-enable @typescript-eslint/naming-convention */
})

const unpackLoginError = flow(
  option.fromPredicate(axios.isAxiosError),
  option.chain((e) => option.fromNullable(e.response)),
  option.map((e) => e.data),
  option.chain(option.fromPredicate(LoginError.is)),
  option.chain(
    option.fromPredicate(
      (e) =>
        e.error_description === 'Missing two factor authentication code' ||
        e.error_description === 'Invalid two factor authentication code'
    )
  ),
  option.map((): LoginStatus => '2FA')
)

const LoginResult = t.type({ wallets: t.array(t.string) })

export const signIn = (user: Login, cancelToken?: axios.CancelToken) =>
  pipe(
    axios.post(
      '/auth/login',
      pipe(
        user,
        record.filter((v) => v !== '')
      ),
      { cancelToken, decoder: LoginResult }
    ),
    task.map((v) =>
      either.isRight(v)
        ? v
        : pipe(
            unpackLoginError(v.left),
            either.fromOption(() => v.left)
          )
    )
  )

export const googleSignIn = (
  googleAccessToken: GoogleLogin,
  cancelToken?: axios.CancelToken
) =>
  pipe(
    axios.post(
      '/auth/gsignin',
      pipe(
        googleAccessToken,
        record.filter((v) => v !== '')
      ),
      { cancelToken, decoder: LoginResult }
    ),
    task.map((v) =>
      either.isRight(v)
        ? v
        : pipe(
            unpackLoginError(v.left),
            either.fromOption(() => v.left)
          )
    )
  )

export const getAccount = (cancelToken?: axios.CancelToken) =>
  axios.get('/uaa/api/account', { cancelToken, decoder: UAAAccount })

const Wallets = t.array(t.string)

export const getWallets = (cancelToken?: axios.CancelToken) =>
  axios.get('/cloudwallet/wallet1.0/user/wallets', {
    cancelToken,
    decoder: Wallets,
  })

export const logout = (cancelToken?: axios.CancelToken) =>
  axios.post('/auth/logout', {}, { cancelToken, decoder: t.any })
export const signup = (signupData: SignUp, cancelToken?: axios.CancelToken) =>
  axios.post('/uaa/api/register-web-wallet', signupData, {
    cancelToken,
    decoder: t.unknown,
  })

export const verifyOrganizationNameExists = (
  name: string,
  cancelToken?: axios.CancelToken
) =>
  axios.get(`/uaa/api/organizations/${name}/exists`, {
    cancelToken,
    decoder: t.unknown,
  })

export const verifyUserNameExists = (
  name: string,
  cancelToken?: axios.CancelToken
) =>
  axios.get(`/uaa/api/users/${name}/existsusername`, {
    cancelToken,
    decoder: t.unknown,
  })
export const verifyEmailNameExists = (
  email: string,
  cancelToken?: axios.CancelToken
) =>
  axios.get(`/uaa/api/users/${email}/exists`, {
    cancelToken,

    decoder: VerifyEmailExitResult,
  })
export const organizationSignUp = (
  signUp: OrganizationSignUpWithModules,
  cancelToken?: axios.CancelToken
) =>
  axios.post('/uaa/api/organizations/signup', signUp, {
    cancelToken,
    decoder: t.unknown,
  })
