import { UserOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Form, Input, Row, Typography } from 'antd'
import * as React from 'react'
import { connect } from 'react-redux'
import { matchPath } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { State } from '../../../../shared/state/store'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../../shared/translations/data'
import { getMissing, loadable } from '../../../../shared/types'
import { signupAction, updateSignUpFormAction } from '../../Login/types'

const mapStateToProps = ({
  account: { signup, loginResult, account, signupResult },
  locale,
}: State) => ({
  visible: !!matchPath(location.pathname, '/accounts/login/signup'),
  signUpData: signup,
  messageReader: translatedMessage(locale),
  missing: getMissing(signup),
  loading: loadable.isLoading(loginResult) || loadable.isLoading(account),
  signupLoading: loadable.isLoading(signupResult),
  isSignupOk: loadable.isOk(signupResult),
  signupResult: signupResult,
})

const mapDispatchToProps = {
  updateSignUpFormAction: updateSignUpFormAction,
  signup: signupAction,
}
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps
type Props = StateProps & DispatchProps
const UsernameForm = styled(Form)`
  display: grid;
  grid-gap: 8px;
  overflow: hidden;
`
const StepContainer = styled.div`
  width: 200%;
  transition: transform 0.2s ease-out;
  display: flex;
  & > *:first-child {
    transition: height 0.2s ease-out;
  }
  & > * {
    width: 50%;
    padding: 0 4px;
  }
`
const SubmitButton = styled(Button)`
  width: 30%;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 5%;
  margin-right: 5%;
`
const format = /[ `!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/

const SetUsername = ({
  signUpData,
  isSignupOk,
  updateSignUpFormAction,
  messageReader,
  missing,
  signup,
  signupResult,
}: Props) => (
  <>
    <Row>
      <Col span={24}>
        <Typography.Title level={4}>
          <TranslatedMessage id="signUp" />
        </Typography.Title>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <UsernameForm>
          <StepContainer>
            <div>
              <Form.Item
                help={
                  format.test(signUpData.login.value) ? (
                    <TranslatedMessage id="noSpecialCaractere" />
                  ) : missing.login ? (
                    <TranslatedMessage id="requiredUsername" />
                  ) : (
                    <TranslatedMessage id="changeUsername" />
                  )
                }
                hasFeedback={missing.login}
                validateStatus={
                  missing.login || format.test(signUpData.login.value)
                    ? 'error'
                    : 'success'
                }
              >
                <Input
                  defaultValue={signUpData.login.value}
                  prefix={<UserOutlined />}
                  name="username"
                  id="username"
                  placeholder={messageReader('username')}
                  required={true}
                  disabled={false}
                  value={signUpData.login.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateSignUpFormAction({
                      login: e.target.value,
                    })
                  }}
                />
              </Form.Item>
            </div>
          </StepContainer>
          {loadable.isErr(signupResult) && (
            <Alert
              message={<TranslatedMessage id="signupFailed" />}
              type="error"
            />
          )}

          {isSignupOk && (
            <Alert
              message={<TranslatedMessage id="signupSuccess" />}
              type="success"
            />
          )}
          <Row justify="space-between">
            <SubmitButton type="primary">
              <Link to={'/signup/email'}>
                <TranslatedMessage id="back" />
              </Link>
            </SubmitButton>
            <SubmitButton
              onClick={signup}
              disabled={
                !signUpData.login.value || format.test(signUpData.login.value)
              }
              htmlType="submit"
              type="primary"
            >
              <TranslatedMessage id="signUp" />
            </SubmitButton>
          </Row>
        </UsernameForm>
      </Col>
    </Row>
  </>
)

export default connect(mapStateToProps, mapDispatchToProps)(SetUsername)
