{
  "employees": "Employées",
  "employee": "Employée",
  "modules": "Modules",
  "entertaiment": "Divertissement",
  "organizationEmployees": "Nombre d'employés",
  "organization": "Organisation",
  "bank": "Banque",
  "companySettings": "Paramètres de l'organisation",
  "userexists": "Ce compte existe déjà!  ",
  "next": "Next",
  "back": "Précédent",
  "genratorPassword": "Vous pouvez utiliser ce mot de pass",
  "changeUsername": "vous pouvez changer votre username",
  "nextDescription": "Entrez votre e-mail et cliquez sur suivant pour continuer le processus d'inscription",
  "setPasswordDescription": "Pour terminer le processus d'inscription, veuillez définir votre mot de passe pour que tu puisses connecter",
  "setPassword": "Ajouter",
  "addPassword": "Ajout du mot de pass",
  "noSpecialCaractere": "Pas de caractères spéciaux sauf . - _",
  "confirm": "Confirmer",
  "security": "Sécurité du compte",
  "services": "CENTRALE",
  "authorities": "Autorités",
  "account": "Mon compte",
  "tfa": "Authentification à deux facteurs",
  "tfacode": "Code d'authentification à deux facteurs",
  "login": "Connexion",
  "logout": "Déconnexion",
  "signUp": "S'inscrire",
  "password": "Mot de passe",
  "currentPassword": "Mot de passe actuel",
  "newPassword": "Nouveau mot de passe",
  "confirmPassword": "Confirmer mot de passe",
  "username": "Nom d'utilisateur",
  "requiredUsername": "Il faut entrer un nom d'utilisateur",
  "requiredPassword": "Il faut entrer un mot de passe",
  "invalidPassword": "Le mot de passe doit comporter au moins 8 caractères",
  "mismatchedPasswords": "Les mots de passe ne correspondent pas",
  "requiredEmail": "Il faut entrer une address e-mail",
  "requiredCode": "Il faut entrer enter code d'authentification à deux facteurs",
  "invalidEmail": "Addresse e-mail incorrecte",
  "loginFailed": "Échec de la connexion, vérifiez votre nom d'utilisateur et mot de passe ou cliquer sur S'inscrire pour créer un nouveau compte",
  "createAccountPrompt": "Voulez vous créer un vouveau compte !",
  "signupFailed": "Signup request was failed",
  "signupSuccess": " La demande de inscription a réussi, veuillez vérifier votre adresse e-mail et activer votre compte",
  "en": "Anglais",
  "fr": "Français",
  "forgotPassword": "Mot de passe oublié?",
  "lostTFACode": "Code d'authentification à deux facteurs perdu?",
  "resendTFACode": "Renvoyer code d'authentification à deux facteurs",
  "resend": "Renvoyer",
  "resetPassword": "Réinitialiser le mot de passe",
  "changePassword": "Changer votre mot de passe",
  "changeRequestFailed": "Demande de changement de mot de passe échouée",
  "changeRequestSuccess": "Demande de changement de mot de passe réussie",
  "tfaRequestFailed": "Demande de renvoie échouée, veuillez réessayer",
  "tfaRequestSuccess": "Demande de renvoie réussie",
  "resetRequestFailed": "Demande de réinitialisation échouée, veuillez réessayer",
  "resetRequestSuccess": "Demande de réinitialisation réussie, verifiez votre boîte de réception",
  "finishResetFailed": "Réinitialisation du mot de passe échouée",
  "finishResetSuccess": "Réinitialisation du mot de passe réussie",
  "finishSetFailed": "Ajout du mot de passe échouée",
  "finishSetSuccess": "Ajout du mot de passe réussie",
  "email": "Addresse e-mail",
  "firstName": "Nom",
  "lastName": "Prénom",
  "fullName": "Nom complet",
  "cancel": "Annuler",
  "ROLE_USER": "Utilisateur",
  "ROLE_ADMIN": "Administrateur",
  "ROLE_SUPER_ADMIN": "Super Administrateur",
  "backofficeDescription": "Gérez les actifs, les clients, les politiques et plus encore",
  "dashboardDescription": "Surveillez votre historique de transactions et vos informations de découverte",
  "webwalletDescription": "Utilisez et créez des portefeuilles pour stocker vos actifs",
  "billsDescription": "Créer, collecter et suivre les créances",
  "marketplaceDescription": "Créez des offres et échangez vos actifs sur le marché",
  "enabled": "Activé",
  "disabled": "Désactivé",
  "enable": "Activer",
  "disable": "Désactiver",
  "requiredFirstName": "Il faut entrer nom",
  "requiredLastName": "Il faut entrer prénom",
  "setUsername": "Ajout d'un nom d'utilisateur",
  "setEmail": "Ajout d'un Email",
  "emailexists": "Cette adresse est utilisé !",
  "personalSetting": "Paramètres personnels",
  "organizationSettings": "paramètres de l'entreprise",
  "googleSignup": "Inscription avec Google",
  "organizationInfo": "Informations sur l'organisation",
  "userInfo": "Utilisateurs de l'organisation",
  "activity": "Activité",
  "googleLogin": "Conexion avec Google",
  "createAccountQuestion": "Je n'ai pas de compte ?",
  "personnalInformation": "Informations Personnelles",
  "SecurityInformation": "Information de sécurité",
  "picture": "Image",
  "selectPicture": "Sélectionnez une image",
  "upload": "Télécharger",
  "emailLabel": "Email",
  "organizationLabel": "Organisation",
  "jobTitle": "Profession",
  "language": "Langue",
  "save": "Sauvegarder",
  "missingUsernameMsg": "Veuillez saisir votre nom d'utilisateur!",
  "missingEmailMsg": "Veuillez saisir votre email!",
  "missingFirstnameMsg": "Veuillez saisir votre prénom!",
  "missingLastnameMsg": "Veuillez saisir votre nom!",
  "missingJobTitleMsg": "Veuillez saisir votre profession!",
  "enableTwoFactor": "Activer l'authentification à deux facteurs",
  "towFactor": "Authentification à deux facteurs",
  "prosperUs_Url": "ProsperUs_Url",
  "asset": "Asset",
  "country": "Pays",
  "Fiscal_Year": "Année fiscale",
  "fiscal_ID": " Id fiscal",
  "Financial_Sector": "Secteur financier",
  "organizationName": "Nom de l'organisation",
  "organizationModule": "Modules d'organization ",
  "setOrganization": "Ajouter Organisation",
  "setUser": "Ajouter User",
  "confirmation": "Confirm",
  "AddUser": "Ajouter Utilisateur ",
  "AddOrganizationUser": "Ajouter utilisateur d'organisation",
  "EditOrganizationUser": "Modifier utilisateur d'organisation",
  "Permissions": "Permissions",
  "Contributor": " Contributeur",
  "Viewer": "Consultant",
  "None": "Rien",
  "SelectUserModalText": "êtes-vous sûr vous voulez enlever cet utilisateur",
  "SelectUserModalText2": "tous les informations associées à cet utilisateur seront perdues",
  "missingCompanyNameMsg": "veuillez entrer le nom de votre organisation!",
  "missingBankMsg": "veuillez entrer le nom de votre banque!",
  "missingRIBMsg": "veuillez entrer votre RIB!",
  "missingFullNameMsg": "veuillez entrer votre nom complet!",
  "IBAN": "IBAN",
  "OrganizationUrl": "Url de l'organisation",
  "OrganizationNameExist": "le nom de l'organisation existe déjà",
  "Next": "suivant",
  "isVerifEmail": "l'email de cet utilisateur existe déjà, si vous cliquez sur soumettre l'utilisateur sera associé à la nouvelle organisation",
  "isVerifyUserName": "le nom de cet utilisateur existe déjà",
  "Previous": "retour",
  "Submit": "soumettre",
  "OrganizationSignUp": "Inscription d'organization",
  "SLOGEN": " The Enabler of Digital Transactions.",
  "AlreadyHaveAnAccount": "Vous avez un compte déja ?",
  "No-Authority": "Pas d'authorisation",
  "NOAUTHORG": "Vous n'avez pas accès à cette page, merci de contacter votre administrateur",
  "NoModulePermissions": "Vous n’êtes pas autorisé sur cette page, merci de contacter votre administrateur",
  "Loading": "Chargement",
  "TermsOfUse": "Conditions d'utilisation",
  "Compliance": "Conformité",
  "Support": "Assistance",
  "Contact": "Contact",
  "CopyRightMessage": " Copyright © 2017-2020 Prosperus | Politique de confidentialité",
  "EmailOrUserName": "Email ou nom d'utilisateur",

  "ACCOUNT/PUT_ORGANIZATION_USERS_RESULT_SUCCESS": "L'utilisateur a eté modifié avec success ",
  "ACCOUNT/PUT_ORGANIZATION_USERS_RESULT_ERROR": "Modification de Utilisateur a echoue",
  "ACCOUNT/POST_ORGANIZATION_USERS_RESULT_SUCCESS": "L'utilisateur a eté ajouter avec sucess",
  "ACCOUNT/POST_ORGANIZATION_USERS_RESULT_ERROR": "L'ajout de Utilisateur a echoue",
  "ACCOUNT/login_result_RESULT_ERROR": "log in a echoué",
  "ACCOUNT/login_result_RESULT_SUCCESS": "Vous avez eté connecté ou platform",
  "ACCOUNT/update_result_RESULT_SUCCESS": "Les information d'utilisateur ont eté modifié",
  "ACCOUNT/update_result_RESULT_ERROR": "Modification a de l'utilisateur a echoué",
  "ACCOUNT/update_organization_result_RESULT_SUCCESS": "les information d'organisation a eté modifié ",
  "ACCOUNT/update_organization_result_RESULT_ERROR": "Modification a de les information d'organisation  a echoué",
  "BACKOFFICE": "BackOffice",
  "BILLS": "Bills",
  "DASHBOARD": "Dashboard",
  "MARKETPLACE": "MarketPlace",
  "WALLET": "Wallet",
  "PersonalAccount": "Compte Personal",
  "Services": "Services",
  "Public_Sector": "Sector Public ",
  "NgoNonProfit": "Ngo Non Profit",
  "Agriculture": "Agriculture",
  "Entertainment": "Entertainment",
  "Information-Technologies": "Technologies d'information",
  "Consumer Goods": "Consumer Goods",
  "Less-then-5": "Moins de 5",
  "Between-5-and-10": "Entre 5 et 10",
  "Between-10-and-20": "Entre 10 et 20",
  "Between-20-and-50": "Entre 20 et 50",
  "Between-50-and-100": "Entre 50 et 100",
  "Greater-then-100": "Plus de 100",
  "defaultUnitRequired": "default Unit Required"
}
