import { LeftOutlined, LockOutlined, UserOutlined } from '@ant-design/icons'
import { Alert, Button, Card, Form, Input, Row, Typography } from 'antd'
import { option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'
import { GoogleLogin } from 'react-google-login'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../shared/translations/data'
import { getMissing, loadable } from '../../../shared/types'
import {
  Login,
  loginAction,
  resetLogin,
  sendgoogleAccessToken,
  updateFormAction,
  updateSignUpFormAction,
} from './types'

const mapStateToProps = ({
  account: { signup, login, account, loginResult },
  locale,
}: State) => ({
  messageReader: translatedMessage(locale),
  loginData: login,
  signUpData: signup,
  loginFailed: loadable.isErr(loginResult),
  loading: loadable.isLoading(loginResult) || loadable.isLoading(account),

  step: pipe(
    loginResult,
    loadable.toOption,
    option.map((v): 0 | 1 => (v === '2FA' ? 1 : 0)),
    option.getOrElse((): 0 | 1 => 0)
  ),
  missing: getMissing(login),
})

const mapDispatchToProps = {
  updateFormAction,
  updateSignUpFormAction: updateSignUpFormAction,
  login: loginAction,
  cancel: resetLogin,
  googleLogin: sendgoogleAccessToken,
}

const mergeProps = (
  s: ReturnType<typeof mapStateToProps>,
  {
    updateFormAction,
    updateSignUpFormAction,
    login,
    ...d
  }: typeof mapDispatchToProps
) => ({
  ...s,
  ...d,
  submit: () => {
    return login()
  },
  updateSignUpFormAction: updateSignUpFormAction,
  set: (key: keyof Login) => (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormAction({ [key]: e.target.value })
  },
})

type Props = ReturnType<typeof mergeProps>

const SubmitButton = styled(Button)``

const LoginForm = styled(Form)`
  display: grid;
  grid-gap: 2px;
  overflow: hidden;
`

const StepContainer = styled.div`
  width: 200%;
  transform: translateX(${({ step }: { step: 0 | 1 }) => step * -50}%);
  transition: transform 0.2s ease-out;
  display: flex;
  & > *:first-child {
    transition: height 0.2s ease-out;
  }
  & > * {
    width: 50%;
    padding: 0 4px;
  }
`

const LoginHeader = styled(Typography.Title)`
  text-align: center;
  margin-bottom: 24px;
`

const ForgotButtons = styled.div`
  display: grid;
  margin-top: 24px;
  margin-bottom: 24px;
`

const LoginCard = styled(Card)`
  max-width: 512px;
`

const LoginComponent = ({
  messageReader,
  loginData: { username, password, tfacode },
  loginFailed,
  loading,
  set,
  submit,
  missing,
  step,
  cancel,
  googleLogin,
  updateSignUpFormAction,
}: Props) => {
  const [isGoogleConnection, setIsGoogleConnection] = React.useState(false)
  const [isGoogleSignUp, setIsGoogleSignUp] = React.useState(false)
  const intl = useIntl()

  const responseGoogle = (response: any) => {
    updateSignUpFormAction({
      email: response.profileObj.email,
      firstName: response.profileObj.givenName,
      login: response.profileObj.email.split('@')[0],
      lastName: response.profileObj.familyName,
    })
    googleLogin({ token: response.getAuthResponse().id_token })

    setIsGoogleConnection(true)
  }

  const responseGoogleSignup = (response: any) => {
    updateSignUpFormAction({
      email: response.profileObj.email,
      firstName: response.profileObj.givenName,
      login: response.profileObj.email.split('@')[0],
      lastName: response.profileObj.familyName,
    })
    setIsGoogleSignUp(true)
  }

  return (isGoogleConnection && loginFailed) || isGoogleSignUp ? (
    <Redirect to="/auth/signup/email" />
  ) : (
    <LoginCard>
      <LoginHeader level={4}>
        <TranslatedMessage id="login" />
      </LoginHeader>
      <LoginForm onFinish={submit}>
        <StepContainer step={step}>
          <div>
            <Form.Item
              help={
                missing.username && <TranslatedMessage id="requiredUsername" />
              }
              hasFeedback={missing.username}
              validateStatus={missing.username ? 'error' : 'success'}
            >
              <Input
                size="small"
                name="email"
                id="email"
                autoComplete="email"
                prefix={<UserOutlined />}
                placeholder={messageReader('EmailOrUserName')}
                value={username.value}
                type="Text"
                onChange={set('username')}
                disabled={loading || step !== 0}
              />
            </Form.Item>
            <Form.Item
              help={
                missing.password && <TranslatedMessage id="requiredPassword" />
              }
              hasFeedback={missing.password}
              validateStatus={missing.password ? 'error' : 'success'}
            >
              <Input
                size="small"
                prefix={<LockOutlined />}
                placeholder={messageReader('password')}
                required={true}
                name="password"
                autoComplete="password"
                value={password.value}
                onChange={set('password')}
                type="password"
                id="password"
                disabled={loading || step !== 0}
              />
            </Form.Item>
          </div>
          <div>
            <Button type="link" onClick={cancel}>
              <LeftOutlined />
              <TranslatedMessage id="back" />
            </Button>
            <Form.Item
              help={missing.tfacode && <TranslatedMessage id="requiredCode" />}
              hasFeedback={missing.tfacode}
              validateStatus={missing.tfacode ? 'error' : 'success'}
            >
              <Input
                autoComplete="off"
                prefix={<UserOutlined />}
                placeholder={messageReader('tfacode')}
                value={tfacode.value}
                onChange={set('tfacode')}
                disabled={loading || step !== 1}
              />
            </Form.Item>
          </div>
        </StepContainer>
        {loginFailed && (
          <Alert
            message={loginFailed && <TranslatedMessage id="loginFailed" />}
            type="error"
          />
        )}
        <SubmitButton
          disabled={
            loading ||
            (step === 0 && (!username.value || !password.value)) ||
            (step === 1 && !tfacode.value)
          }
          htmlType="submit"
          type="primary"
        >
          <TranslatedMessage id="login" />
        </SubmitButton>
        <Row justify="space-between">
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}
            onSuccess={responseGoogle}
            cookiePolicy="single_host_origin"
            buttonText={intl.formatMessage({
              id: 'googleLogin',
              defaultMessage: 'google login',
            })}
          />
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}
            buttonText={intl.formatMessage({
              id: 'googleSignup',
              defaultMessage: 'google signup',
            })}
            onSuccess={responseGoogleSignup}
            cookiePolicy="single_host_origin"
          />
        </Row>
      </LoginForm>
      <ForgotButtons>
        <Button disabled={loading} type="link">
          <Link to={step === 0 ? '/auth/reset/init' : '/auth/request-tfa'}>
            <TranslatedMessage
              id={step === 0 ? 'forgotPassword' : 'lostTFACode'}
            />
          </Link>
        </Button>
      </ForgotButtons>
      <Typography.Paragraph style={{ textAlign: 'center' }}>
        <TranslatedMessage id="createAccountQuestion" />

        <Button type="link" color="primary">
          <Link to="/auth/signup/email">
            <TranslatedMessage id="signUp" />
          </Link>
        </Button>
      </Typography.Paragraph>
    </LoginCard>
  )
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(LoginComponent)
