import { LoadableC } from './../../shared/types/Loadable'
import { actionCreator } from './../../shared/types/Actions'
import { Action } from 'redux'
import { LocationChangeAction } from 'connected-react-router'
import { array, option } from 'fp-ts'
import { eqString } from 'fp-ts/lib/Eq'
import { Predicate } from 'fp-ts/lib/function'
import { pipe } from 'fp-ts/lib/pipeable'
import * as t from 'io-ts'
import { withFallback } from 'io-ts-types'

import {
  FailableAction,
  failableActionCreator,
  FormData as FormDataType,
  Loadable,
  loadable,
  PayloadAction,
  payloadActionCreator,
} from '../../shared/types'
import { State as LoginState, UAAAccount } from '../Authentication/Login'

export type PasswordForm = {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

export const emptyPasswordForm: FormDataType<PasswordForm> = {
  currentPassword: { value: '', pristine: true },
  newPassword: { value: '', pristine: true },
  confirmPassword: { value: '', pristine: true },
}
export const Organization = t.type(
  {
    id: withFallback(t.number, 0),
    bankName: withFallback(t.string, ''),
    url: withFallback(t.string, ''),
    iban: withFallback(t.string, ''),
    fiscalYear: withFallback(t.number, new Date().getFullYear()),
    fiscalId: withFallback(t.string, ''),

    name: withFallback(t.string, ''),
    rib: withFallback(t.number, 0),
    employeesNumber: withFallback(t.string, ''),
    activity: withFallback(t.string, ''),
    country: withFallback(t.string, ''),
    asset: withFallback(t.string, ''),
    users: withFallback(t.array(UAAAccount), []),
    modules: withFallback(t.array(t.string), []),
  },
  'Organization'
)
export const emptyOrganization: Organization = {
  id: 0,
  bankName: '',
  url: '',
  iban: '',
  fiscalYear: 2020,
  fiscalId: '',
  name: '',
  rib: 0,
  employeesNumber: '',
  activity: '',
  country: '',
  asset: '',
  users: [],
  modules: [],
}
export type Organization = t.TypeOf<typeof Organization>

export type ImageType = {
  file: any
}

export type State = {
  updateResult: Loadable<UAAAccount>
  changePasswordForm: option.Option<FormDataType<PasswordForm>>
  changePasswordResult: Loadable<unknown>
  organization: Loadable<Organization>
  organizationUsers: Loadable<UAAAccount[]>
  profileSettingTabNumber: string
  selectedFile: option.Option<File>
  uploadImageResult: Loadable<unknown>
  isAccountsModalOpen: boolean
}
export const StateC = t.type({
  organization: LoadableC(Organization),
})

export const Authorities = t.keyof({
  /* eslint-disable @typescript-eslint/naming-convention */
  ROLE_USER: null,
  ROLE_ADMIN: null,
  ROLE_SUPER_ADMIN: null,
  /* eslint-enable @typescript-eslint/naming-convention */
})

type Authorities = t.TypeOf<typeof Authorities>

export type Application = {
  name: 'BACKOFFICE' | 'BILLS' | 'DASHBOARD' | 'MARKETPLACE' | 'WALLET'
  shouldRender: Predicate<LoginState>
  href: string
  icon: 'BACKOFFICE' | 'BILLS' | 'DASHBOARD' | 'MARKETPLACE' | 'WALLET'
  descriptionKey:
    | 'backofficeDescription'
    | 'dashboardDescription'
    | 'webwalletDescription'
    | 'billsDescription'
    | 'marketplaceDescription'
}

const hasAuthority = (role: string) => (s: LoginState) =>
  pipe(
    s.account,
    loadable.toOption,
    option.flatten,
    option.filter((a) => array.elem(eqString)(role, a.authorities)),
    option.isSome
  )

export const applications: Application[] = [
  {
    name: 'WALLET',
    icon: 'WALLET',
    shouldRender: (s) =>
      pipe(
        s.loginResult,
        loadable.toOption,
        option.filterMap((a) =>
          a === '2FA' ? option.none : option.some(a.wallets)
        ),
        option.alt(() =>
          pipe(
            s.account,
            loadable.toOption,
            option.flatten,
            option.map((a) => a.wallets)
          )
        ),
        option.filter(array.isNonEmpty),
        option.isSome
      ),
    href: '/web-wallet',
    descriptionKey: 'webwalletDescription',
  },
  {
    name: 'MARKETPLACE',
    icon: 'MARKETPLACE',
    shouldRender: (s) =>
      pipe(
        s.loginResult,
        loadable.toOption,
        option.filterMap((a) =>
          a === '2FA' ? option.none : option.some(a.wallets)
        ),
        option.alt(() =>
          pipe(
            s.account,
            loadable.toOption,
            option.flatten,
            option.map((a) => a.wallets)
          )
        ),
        option.filter(array.isNonEmpty),
        option.isSome
      ),
    href: '/marketplace',
    descriptionKey: 'marketplaceDescription',
  },
  {
    name: 'BILLS',
    icon: 'BILLS',
    shouldRender: (s) =>
      pipe(
        s.loginResult,
        loadable.toOption,
        option.filterMap((a) =>
          a === '2FA' ? option.none : option.some(a.wallets)
        ),
        option.alt(() =>
          pipe(
            s.account,
            loadable.toOption,
            option.flatten,
            option.map((a) => a.wallets)
          )
        ),
        option.filter(array.isNonEmpty),
        option.isSome
      ),
    href: '/prosper-bills',
    descriptionKey: 'billsDescription',
  },
  {
    name: 'DASHBOARD',
    shouldRender: hasAuthority('ROLE_ADMIN'),
    icon: 'DASHBOARD',
    href: '/dashboard',
    descriptionKey: 'dashboardDescription',
  },
  {
    name: 'BACKOFFICE',
    shouldRender:
      hasAuthority('ROLE_ADMIN') || hasAuthority('ROLE_SUPER_ADMIN'),
    icon: 'BACKOFFICE',
    href: '/backoffice',
    descriptionKey: 'backofficeDescription',
  },
]

export type GetOrganizationUsers = PayloadAction<
  'ACCOUNT/GET_ORGANIZATION_USERS',
  string
>
export const getOrganizationUsers = payloadActionCreator<GetOrganizationUsers>(
  'ACCOUNT/GET_ORGANIZATION_USERS'
)
export type GetOrganizationUsersResultAction = FailableAction<
  'ACCOUNT/GET_ORGANIZATION_USERS_RESULT',
  UAAAccount[]
>
export const getOrganizationUsersResultAction = failableActionCreator<GetOrganizationUsersResultAction>(
  'ACCOUNT/GET_ORGANIZATION_USERS_RESULT'
)

export type PostOrganizationUsers = PayloadAction<
  'ACCOUNT/POST_ORGANIZATION_USERS',
  { organizationName: string; user: any }
>
export const postOrganizationUsers = payloadActionCreator<PostOrganizationUsers>(
  'ACCOUNT/POST_ORGANIZATION_USERS'
)
export type PostOrganizationUsersResultAction = FailableAction<
  'ACCOUNT/POST_ORGANIZATION_USERS_RESULT',
  UAAAccount[]
>
export const postOrganizationUsersResultAction = failableActionCreator<PostOrganizationUsersResultAction>(
  'ACCOUNT/POST_ORGANIZATION_USERS_RESULT'
)

export type PutOrganizationUsers = PayloadAction<
  'ACCOUNT/PUT_ORGANIZATION_USERS',
  { organizationName: string; user: any }
>
export const putOrganizationUsers = payloadActionCreator<PutOrganizationUsers>(
  'ACCOUNT/PUT_ORGANIZATION_USERS'
)
export type PutOrganizationUsersResultAction = FailableAction<
  'ACCOUNT/PUT_ORGANIZATION_USERS_RESULT',
  UAAAccount[]
>
export const putOrganizationUsersResultAction = failableActionCreator<PutOrganizationUsersResultAction>(
  'ACCOUNT/PUT_ORGANIZATION_USERS_RESULT'
)

export type DeleteOrganizationUsers = PayloadAction<
  'ACCOUNT/DELETE_ORGANIZATION_USERS',
  { organizationName: string; userId: number }
>
export const deleteOrganizationUsers = payloadActionCreator<DeleteOrganizationUsers>(
  'ACCOUNT/DELETE_ORGANIZATION_USERS'
)
export type DeleteOrganizationUsersResultAction = FailableAction<
  'ACCOUNT/DELETE_ORGANIZATION_USERS_RESULT',
  UAAAccount[]
>
export const deleteOrganizationUsersResultAction = failableActionCreator<DeleteOrganizationUsersResultAction>(
  'ACCOUNT/DELETE_ORGANIZATION_USERS_RESULT'
)

export type UpdateOrganizationAction = PayloadAction<
  'ACCOUNT/update_organization',
  Organization
>
export const updateOrganizationAction = payloadActionCreator<UpdateOrganizationAction>(
  'ACCOUNT/update_organization'
)
export type UpdateOrganizationResultAction = FailableAction<
  'ACCOUNT/update_organization_result',
  Organization
>
export const updateOrganizationResultAction = failableActionCreator<UpdateOrganizationResultAction>(
  'ACCOUNT/update_organization_result'
)

export type GetOrganizationAction = PayloadAction<
  'ACCOUNT/GET_ORGANIZATION',
  string
>
export const getOrganizationAction = payloadActionCreator<GetOrganizationAction>(
  'ACCOUNT/GET_ORGANIZATION'
)
export type GetOrganizationResultAction = FailableAction<
  'ACCOUNT/GET_ORGANIZATION_RESULT',
  Organization
>
export const getOrganizationResultAction = failableActionCreator<GetOrganizationResultAction>(
  'ACCOUNT/GET_ORGANIZATION_RESULT'
)

export type UpdateAccountAction = PayloadAction<
  'ACCOUNT/update',
  Partial<UAAAccount>
>
export const updateAccountAction = payloadActionCreator<UpdateAccountAction>(
  'ACCOUNT/update'
)

export type UpdateAccountResult = FailableAction<
  'ACCOUNT/update_result',
  UAAAccount
>
export const updateAccountResult = failableActionCreator<UpdateAccountResult>(
  'ACCOUNT/update_result'
)

export type ChangePasswordAction = PayloadAction<
  'ACCOUNT/change_password',
  PasswordForm
>
export const changePasswordAction = payloadActionCreator<ChangePasswordAction>(
  'ACCOUNT/change_password'
)

export type ChangePasswordResult = FailableAction<
  'ACCOUNT/change_password_result',
  any
>
export const changePasswordResult = failableActionCreator<ChangePasswordResult>(
  'ACCOUNT/change_password_result'
)

export type UpdateProfileTab = PayloadAction<'ACCOUNT/updateProfileTab', string>
export const updateProfileTab = payloadActionCreator<UpdateProfileTab>(
  'ACCOUNT/updateProfileTab'
)

export type UpdateSelectedFile = PayloadAction<
  'ACCOUNT/update_selected_file',
  File
>
export const updateSelectedFile = payloadActionCreator<UpdateSelectedFile>(
  'ACCOUNT/update_selected_file'
)

export type UploadImageAction = PayloadAction<'ACCOUNT/upload_image', ImageType>
export const uploadImageAction = payloadActionCreator<UploadImageAction>(
  'ACCOUNT/upload_image'
)

export type UploadImageResultAction = FailableAction<
  'ACCOUNT/upload_image_result',
  unknown
>
export const uploadImageResultAction = failableActionCreator<UploadImageResultAction>(
  'ACCOUNT/upload_image_result'
)
export type OpenAccountsModal = Action<'ACCOUNT/OPEN_ACCOUNT_MODAL'>
export const openAccountsModal = actionCreator<OpenAccountsModal>(
  'ACCOUNT/OPEN_ACCOUNT_MODAL'
)

export type CloseAccountsModal = Action<'ACCOUNT/CLOSE_ACCOUNT_MODAL'>
export const closeAccountsModal = actionCreator<CloseAccountsModal>(
  'ACCOUNT/CLOSE_ACCOUNT_MODAL'
)
export type ChoseWorkSpace = PayloadAction<'ACCOUNT/CHOSE_WORKSPACE', string>
export const choseWorkSpace = payloadActionCreator<ChoseWorkSpace>(
  'ACCOUNT/CHOSE_WORKSPACE'
)
export type UpdateOrganizationModulesAction = PayloadAction<
  'ACCOUNT/update_organization_modules',
  Organization
>
export const updateOrganizationModulesAction = payloadActionCreator<UpdateOrganizationModulesAction>(
  'ACCOUNT/update_organization_modules'
)

export type UpdateOrganizationModulesResultAction = FailableAction<
  'ACCOUNT/update_organization_modules_result',
  Organization
>
export const updateOrganizationModulesResultAction = failableActionCreator<UpdateOrganizationModulesResultAction>(
  'ACCOUNT/update_organization_modules_result'
)

export type Actions =
  | UpdateAccountAction
  | UpdateAccountResult
  | ChangePasswordAction
  | ChangePasswordResult
  | LocationChangeAction
  | UpdateProfileTab
  | GetOrganizationAction
  | GetOrganizationResultAction
  | UpdateOrganizationAction
  | UpdateOrganizationResultAction
  | GetOrganizationUsers
  | GetOrganizationUsersResultAction
  | PostOrganizationUsers
  | PostOrganizationUsersResultAction
  | PutOrganizationUsers
  | PutOrganizationUsersResultAction
  | DeleteOrganizationUsers
  | DeleteOrganizationUsersResultAction
  | UploadImageAction
  | UploadImageResultAction
  | UpdateProfileTab
  | UpdateSelectedFile
  | OpenAccountsModal
  | CloseAccountsModal
  | ChoseWorkSpace
  | UpdateOrganizationModulesAction
  | UpdateOrganizationModulesResultAction
