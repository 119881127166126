import { MailOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { State } from '../../../../shared/state/store'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../../shared/translations/data'
import { getMissing } from '../../../../shared/types'
import { updateSignUpFormAction } from '../../Login/types'

const mapStateToProps = ({ account: { signup }, locale }: State) => ({
  signUpData: signup,
  messageReader: translatedMessage(locale),
  missing: getMissing(signup),
})

const mapDispatchToProps = {
  updateSignUpFormAction: updateSignUpFormAction,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps
type Props = StateProps & DispatchProps
const EmailForm = styled(Form)`
  display: grid;
  grid-gap: 8px;
  overflow: hidden;
`
const StepContainer = styled.div`
  width: 200%;
  transition: transform 0.2s ease-out;
  display: flex;
  & > *:first-child {
    transition: height 0.2s ease-out;
  }
  & > * {
    width: 50%;
    padding: 0 4px;
  }
`

const SubmitButton = styled(Button)`
  width: 50%;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 25%;
`

const SetEmail = ({ updateSignUpFormAction, messageReader }: Props) => {
  const [form] = Form.useForm()

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={4}>
            <TranslatedMessage id="signUp" />
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <EmailForm form={form}>
            <StepContainer>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: (
                      <TranslatedMessage
                        id="invalidEmail"
                        defaultMessage="Invalid e-mail address"
                      />
                    ),
                  },
                ]}
                tooltip="This is a required field"
              >
                <Input
                  prefix={<MailOutlined />}
                  allowClear
                  type="email"
                  placeholder={messageReader('email')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateSignUpFormAction({
                      email: e.target.value,
                      firstName: e.target.value.split('@')[0],
                      lastName: e.target.value.split('@')[0],
                    })
                  }}
                />
              </Form.Item>
            </StepContainer>
            <Form.Item shouldUpdate className="submit">
              {() => (
                <SubmitButton
                  disabled={
                    !form.isFieldsTouched() ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length > 0
                  }
                  htmlType="submit"
                  type="primary"
                >
                  <Link to={'/auth/signup/username'}>
                    <TranslatedMessage id="next" />
                  </Link>
                </SubmitButton>
              )}
            </Form.Item>
          </EmailForm>
        </Col>
      </Row>
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(SetEmail)
