import { Button, Card, Divider, Steps, Typography } from 'antd'
import * as React from 'react'
import { connect } from 'react-redux'
import { Switch } from 'react-router'
import { Link, Route } from 'react-router-dom'
import styled from 'styled-components'

import { TranslatedMessage } from '../../../shared/translations/data'
import SetEmail from './signUpSteps/setEmail'
import SetUsername from './signUpSteps/setUsername'

const SignUpCard = styled(Card)`
  max-width: 512px;
`

const Signup = () => {
  return (
    <>
      <SignUpCard bordered={false}>
        <Steps
          current={location.pathname === '/accounts/auth/signup/email' ? 0 : 1}
          style={{ color: 'green' }}
        >
          <Steps.Step title={<TranslatedMessage id="setEmail" />}></Steps.Step>
          <Steps.Step title={<TranslatedMessage id="setUsername" />} />
        </Steps>

        <Divider />

        <Switch>
          <Route exact path="/auth/signup/email" component={SetEmail} />
          <Route path="/auth/signup/username" component={SetUsername} />
        </Switch>
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          <TranslatedMessage id="AlreadyHaveAnAccount" />
          <Button type="link" color={'primary'}>
            <Link to={'/auth/login'}>
              <TranslatedMessage id="login" />
            </Link>
          </Button>
        </Typography.Paragraph>
      </SignUpCard>
    </>
  )
}
export default connect(null, null)(Signup)
