import { Newtype, prism, iso } from 'newtype-ts'
import validator from 'validator'

export { Err, Result, Ok, result } from './Result'
export { Loading, NotRequested, Loadable, loadable } from './Loadable'
export { MemoedC, Memoed, memoed } from './Memoed'
export * from './Actions'
export * from './Codec'
export * from './Transaction'
export * from './Profile'
export * from './UserState'
export * from './form'

export type ValueOf<T> = T[keyof T]

export type Unpacked<T> = U<U<U<U<U<U<T>>>>>>

export type U<T> = T extends (...args: any[]) => infer U
  ? U
  : T extends Promise<infer U>
  ? U
  : T

export type Email = Newtype<{ readonly Email: unique symbol }, string>

export const prismEmail = prism<Email>(validator.isEmail)

export const isoEmail = iso<Email>()

export type Password = Newtype<{ readonly Password: unique symbol }, string>

export const prismPassword = prism<Password>((p) => p.length >= 8)
