import { option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { State } from '../state/store'
import { loadable } from '../types'

const mapStateToProps = ({ account: { account } }: State) => ({
  isLoggedIn: pipe(account, loadable.toOption, option.flatten, option.isSome),
})

type Props = RouteProps & ReturnType<typeof mapStateToProps>

const PublicRoute = ({ isLoggedIn, ...rest }: Props) =>
  isLoggedIn ? <Redirect to="/" /> : <Route {...rest} />

export default connect(mapStateToProps)(PublicRoute)
