import { CancelToken } from 'axios'
import { taskEither } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as t from 'io-ts'

import * as axios from '../../axios'
import { getAccount } from '../Authentication/Login/api'
import { UAAAccount } from '../Authentication/Login/types'
import { Organization, PasswordForm } from './types'

const apiUrl = '/uaa/api/account'

export const getAccountApi = (cancelToken?: CancelToken) =>
  getAccount(cancelToken)

export const updateAccount = (account: UAAAccount, cancelToken?: CancelToken) =>
  pipe(
    axios.post(`${apiUrl}`, account, {
      cancelToken,
      encoder: UAAAccount,
      decoder: t.unknown,
    }),
    // TODO: maybe the UAA server should return this value instead.
    taskEither.chain(() => getAccount(cancelToken))
  )

export const changePassword = (req: PasswordForm, cancelToken?: CancelToken) =>
  pipe(
    axios.post(`${apiUrl}/change-password`, req, {
      cancelToken,
      decoder: t.unknown,
    }),
    taskEither.map(() => undefined)
  )

export const updateOrganizationModules = (
  request: Organization,
  cancelToken?: axios.CancelToken
) =>
  axios.post('/uaa/api/organizations/modules', request, {
    cancelToken,
    encoder: Organization,
    decoder: Organization,
  })

export const updateOrganization = (
  request: Organization,
  cancelToken?: axios.CancelToken
) =>
  axios.put('/uaa/api/organizations', request, {
    cancelToken,
    decoder: Organization,
  })

export const getOrganizations = (
  organizationName: string,
  cancelToken?: axios.CancelToken
) =>
  axios.get(`/uaa/api/organizations/${organizationName}`, {
    cancelToken,
    decoder: Organization,
  })
export const getOrganizationsUsers = (
  organizationName: string,
  cancelToken?: axios.CancelToken
) =>
  axios.get(`/uaa/api/organizations/${organizationName}/users`, {
    cancelToken,
    decoder: t.array(UAAAccount),
  })
export const postOrganizationsUsers = (
  organizationName: string,
  user: UAAAccount,
  cancelToken?: axios.CancelToken
) =>
  axios.post(`/uaa/api/organizations/${organizationName}/users`, user, {
    cancelToken,
    decoder: t.array(UAAAccount),
  })
export const putOrganizationsUsers = (
  organizationName: string,
  user: UAAAccount,
  cancelToken?: axios.CancelToken
) =>
  axios.put(`/uaa/api/organizations/${organizationName}/users`, user, {
    cancelToken,
    decoder: t.array(UAAAccount),
  })
export const deleteOrganizationsUsers = (
  organizationName: string,
  userId: number,
  cancelToken?: axios.CancelToken
) =>
  axios.delete_(`/uaa/api/organizations/${organizationName}/users/${userId}`, {
    cancelToken,
    decoder: t.array(UAAAccount),
  })

export const uploadFile = (file: FormData, cancelToken?: axios.CancelToken) =>
  axios.post(`${apiUrl}/upload`, file, {
    cancelToken,
    decoder: t.unknown,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const getFile = (imageUrl: string, cancelToken?: axios.CancelToken) =>
  axios.get(`${apiUrl}/picture/${imageUrl}`, {
    cancelToken,
    decoder: t.unknown,
  })
