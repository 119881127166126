import * as React from 'react'
import { Row, Col, Card, Tabs, Typography } from 'antd'
import styled from 'styled-components'

import PersonalInformationForm from './PersonalInformationForm'
import SecuritySettingsForm from './securitySettingsForm'
import { connect } from 'react-redux'

import { updateProfileTab } from '../types'

import { State } from '../../../shared/state/store'
import { FormattedMessage } from 'react-intl'

const { Title } = Typography
const { TabPane } = Tabs

const CardTitle = styled(Title)`
  color: #51b148 !important;

  font-size: 21px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 2px;
  margin-left: 60px;
`
const mapStateToProps = ({
  accountSetting: { profileSettingTabNumber },
}: State) => ({
  tabNumber: profileSettingTabNumber,
})

const mapDispatchToProps = {
  setTab: (tabKey: string) => updateProfileTab(tabKey),
}
type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const PersonalSetting = ({ tabNumber, setTab }: Props) => {
  return (
    <Row gutter={[40, 8]}>
      <Col span={16} offset={4}>
        <Card>
          <Row>
            <Col span={24}>
              <CardTitle level={1}>
                <FormattedMessage id={'personalSetting'} />
              </CardTitle>
            </Col>
          </Row>
          <Tabs onChange={setTab} activeKey={tabNumber}>
            <TabPane
              tab={<FormattedMessage id={'personnalInformation'} />}
              key="1"
            >
              <PersonalInformationForm />
            </TabPane>
            <TabPane
              tab={<FormattedMessage id={'SecurityInformation'} />}
              key="2"
            >
              <SecuritySettingsForm />
            </TabPane>
          </Tabs>
        </Card>
      </Col>
    </Row>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalSetting)
