import {
  FormData,
  Action,
  PayloadAction,
  payloadActionCreator,
  actionCreator,
  Loadable,
  FailableAction,
  failableActionCreator,
} from '../../../shared/types'
import { LocationChangeAction } from 'connected-react-router'

type Form = { email: string }

export type State = {
  userEmail: FormData<Form>
  requestResult: Loadable<unknown>
}

export type UpdateEmailAction = PayloadAction<'TFA/update', Partial<Form>>
export const updateEmailAction = payloadActionCreator<UpdateEmailAction>(
  'TFA/update'
)

export type RequestTFAAction = Action<'TFA/request'>
export const requestTFAAction = actionCreator<RequestTFAAction>('TFA/request')

export type RequestTFAResult = FailableAction<'TFA/request_result', undefined>
export const requestTFResult = failableActionCreator<RequestTFAResult>(
  'TFA/request_result'
)

export type Actions =
  | UpdateEmailAction
  | RequestTFAAction
  | RequestTFAResult
  | LocationChangeAction
