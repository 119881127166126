import { Layout } from 'antd'
import * as React from 'react'
import { connect } from 'react-redux'

import { State } from '../state/store'
import Header from './header/Header'
import Main from './main/main'

const mapStateToProps = ({}: State) => ({})
const mapDispatchToProps = () => {
  return {}
}
type StateProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>
type Props = StateProps
const MainLayout = ({}: Props) => {
  return (
    <Layout>
      <Header />
      <Main />
    </Layout>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
