import { pipe } from 'fp-ts/lib/pipeable'
import * as effects from 'redux-saga/effects'
import {
  GetAccountResultAction,
  LogoutResultAction,
} from '../../components/Authentication/Login'
import * as accountSetting from './../../components/Account/types'
import * as info from './Info'
import { deleteItem, setItem } from './io'
import { State } from './store'
import { takeLatest } from './saga'

function* saveInfoSaga(_: info.GetInfoResult) {
  const i: State['info'] = yield effects.select(({ info }: State) => info)
  setItem('info', i, info.StateC)()
}
export function* initOrganizationSaga(
  a:
    | accountSetting.GetOrganizationResultAction
    | accountSetting.UpdateOrganizationResultAction
) {
  setItem('organization', { organization: a.payload }, accountSetting.StateC)()
}

function* clearStateSaga(a: LogoutResultAction | GetAccountResultAction) {
  if (a.type === 'ACCOUNT/get_account_result' && a.payload._tag === 'Ok') {
    return
  }
  pipe(deleteItem('organization'))()
}
export function* saga() {
  yield effects.all([
    effects.throttle(5000, 'get_info_result', saveInfoSaga),
    effects.throttle(
      5000,
      'ACCOUNT/GET_ORGANIZATION_RESULT',
      initOrganizationSaga
    ),
    effects.throttle(
      5000,
      'ACCOUNT/update_organization_result',
      initOrganizationSaga
    ),
    takeLatest('ACCOUNT/logout_result', clearStateSaga),
  ])
}
