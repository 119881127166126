import * as React from 'react'
import { FailableAction, Action, actionCreator } from '../types'
import { Actions as AllActions } from '../state/store'
import { FormattedMessage } from 'react-intl'

export type FailableActions = Extract<AllActions, FailableAction<any, any>>
type FailableActionTypes = FailableActions['type']

export type QueueItem = [FailableActionTypes, 'success' | 'error']

export type State = QueueItem[]
export type CloseNotification = Action<'close_notification'>
export const closeNotification = actionCreator<CloseNotification>(
  'close_notification'
)

export type Actions = CloseNotification

type ActionsLookup = Record<
  FailableActionTypes,
  {
    success?: React.ReactNode
    error?: React.ReactNode
  }
>

export const lookup: ActionsLookup = {
  /* eslint-disable @typescript-eslint/naming-convention */
  'ACCOUNT/POST_ORGANIZATION_USERS_RESULT': {
    error: (
      <FormattedMessage id="ACCOUNT/POST_ORGANIZATION_USERS_RESULT_ERROR" />
    ),
    success: (
      <FormattedMessage id="ACCOUNT/POST_ORGANIZATION_USERS_RESULT_SUCCESS" />
    ),
  },
  'ACCOUNT/PUT_ORGANIZATION_USERS_RESULT': {
    error: (
      <FormattedMessage id="ACCOUNT/PUT_ORGANIZATION_USERS_RESULT_ERROR" />
    ),
    success: (
      <FormattedMessage id="ACCOUNT/PUT_ORGANIZATION_USERS_RESULT_SUCCESS" />
    ),
  },
  'ACCOUNT/get_account_result': {},
  get_info_result: {},
  'ACCOUNT/login_result': {
    error: <FormattedMessage id="ACCOUNT/login_result_RESULT_ERROR" />,
    success: <FormattedMessage id="ACCOUNT/login_result_RESULT_SUCCESS" />,
  },
  'ACCOUNT/logout_result': {},
  'ACCOUNT/update_result': {
    error: <FormattedMessage id="ACCOUNT/update_result_RESULT_ERROR" />,
    success: <FormattedMessage id="ACCOUNT/update_result_RESULT_SUCCESS" />,
  },
  'ACCOUNT/DELETE_ORGANIZATION_USERS_RESULT': {},
  'ACCOUNT/GET_ORGANIZATION_RESULT': {},
  'ACCOUNT/GET_ORGANIZATION_USERS_RESULT': {},
  'ACCOUNT/Organization_signUp_result': {},
  'ACCOUNT/Verify_Email_Exists_result': {},
  'ACCOUNT/Verify_Organization_Exists_result': {},
  'ACCOUNT/change_password_result': {},
  'ACCOUNT/signup_result': {},
  'ACCOUNT/update_organization_modules_result': {},
  'ACCOUNT/update_organization_result': {
    error: (
      <FormattedMessage id="ACCOUNT/update_organization_result_RESULT_ERROR" />
    ),
    success: (
      <FormattedMessage id="ACCOUNT/update_organization_result_RESULT_SUCCESS" />
    ),
  },
  'ACCOUNT/upload_image_result': {},
  'ACCOUNT/Verify_USER_NAME_Exists_result': {},
  /* eslint-enable @typescript-eslint/naming-convention */
}
