import { Layout, Spin } from 'antd'
import * as React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import styled from 'styled-components'

import AccountApps from '../../../components/Account/AccountApps'
import OrganizationSetting from '../../../components/Account/organisationSettings/OrganizationSetting '
import personalSetting from '../../../components/Account/personalSettings/personalSetting'
import { State } from '../../state/store'
import { loadable } from '../../types'
import WorkSpaceSelectModal from './WorkSpaceSelectModal'

const mapStateToProps = ({ accountSetting: { organization } }: State) => ({
  isLoading: loadable.isLoading(organization),
})

const mapDispatchToProps = {}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
const FullPageSpin = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const Main = ({ isLoading }: Props) => {
  return (
    <Layout>
      <Layout.Content style={{ padding: '0 50px', marginTop: 43 }}>
        <WorkSpaceSelectModal />
        {isLoading ? (
          <FullPageSpin tip="Loading" />
        ) : (
          <Switch>
            <Route path="/" exact component={AccountApps} />
            <Route path="/personal_setting" component={personalSetting} />
            <Route
              path="/organization_setting"
              component={OrganizationSetting}
            />
          </Switch>
        )}
      </Layout.Content>
    </Layout>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(Main)
