{
  "confirm": "Confirm",
  "security": "Account security",
  "services": "CENTRALE",
  "authorities": "Authorities",
  "account": "My account",
  "tfa": "Two factor authentication",
  "tfacode": "Two factor authentication code",
  "login": "Login",
  "logout": "Log out",
  "signUp": "Sign Up",
  "password": "Password",
  "changePassword": "Change password",
  "currentPassword": "Current password",
  "newPassword": "New password",
  "confirmPassword": "Confirm password",
  "username": "Username",
  "requiredPassword": "Enter a valid password",
  "invalidPassword": "Password must be at least 8 characters long",
  "mismatchedPasswords": "Passwords do not match",
  "requiredUsername": "Enter a valid use Name or e-mail address",
  "noSpecialCaractere": "no special characters except . - _",
  "requiredEmail": "Must enter e-mail address",
  "requiredCode": "Must enter two factor authentication code",
  "invalidEmail": "Invalid e-mail address",
  "loginFailed": "Login failed, check your username and password or click on Signup to create new account",
  "signupFailed": "Signup failed",
  "signupSuccess": "Signup request was succeed please check your email and activate your account",
  "en": "English",
  "fr": "French",
  "forgotPassword": "Forgot password?",
  "lostTFACode": "Lost your TFA code?",
  "resendTFACode": "Resend two factor authentication code",
  "resend": "Resend",
  "resetPassword": "Reset Password",
  "genratorPassword": "You can use this password",
  "next": "Next",
  "back": "Back",
  "setPasswordDescription": "To complete the registration process, please set your password so that you can login",
  "setPassword": "Add",
  "addPassword": "Adding a password",
  "changeUsername": "you can change your username",
  "changeRequestFailed": "Change password request failed",
  "changeRequestSuccess": "Change password request successful",
  "resetRequestFailed": "Reset request failed, check e-mail address",
  "resetRequestSuccess": "Reset request successful, check your inbox",
  "tfaRequestFailed": "Request failed",
  "tfaRequestSuccess": "Request send successfully",
  "finishResetFailed": "Password reset failed",
  "finishResetSuccess": "Password reset successfully",
  "finishSetFailed": "Password set failed",
  "finishSetSuccess": "Password set successfully",
  "email": "E-mail address",
  "cancel": "Cancel",
  "ROLE_USER": "User",
  "ROLE_ADMIN": "Admin",
  "ROLE_SUPER_ADMIN": "Super Admin",
  "backofficeDescription": "Manage assets, customers, policies and more",
  "dashboardDescription": "Monitor your transaction history and discover insights",
  "webwalletDescription": "Use and create wallets to store your assets",
  "billsDescription": "Build, collect & monitor receivables",
  "marketplaceDescription": "Create offers and trade your assets on the market",
  "organizationSettings": "Organization Settings",
  "googleSignup": "Google signup",
  "enabled": "Enabled",
  "disabled": "Disabled",
  "enable": "Enable",
  "disable": "Disable",
  "requiredFirstName": "Must enter firstName",
  "requiredLastName": "Must enter lastName",
  "firstName": "First Name",
  "lastName": "Last Name",
  "fullName": "Full Name",
  "createAccountPrompt": "Do you want to create new account",
  "userexists": "This account is already exist !  ",
  "nextDescription": "Enter your email and click next to continue the  process of sign up",
  "setUsername": "Adding a username",
  "setEmail": "Adding an Email",
  "emailexists": "This Email is already in use !",
  "personalSetting": "Personal settings",
  "companySettings": "Organization Settings",
  "modules": "Modules",
  "organizationInfo": "Organization Information",
  "userInfo": "Organization Users",
  "activity": "Activity",
  "entertaiment": "Entertainment",
  "organizationEmployees": "Number of employees",
  "organizationName": "Name",
  "employees": "Employees",
  "employee": "Employee",
  "bank": "Bank",
  "googleLogin": "Login with Google",
  "createAccountQuestion": "Don't have an account?",
  "personnalInformation": "Personal Information",
  "SecurityInformation": "Security Information",
  "picture": "Picture",
  "selectPicture": "Select Picture",
  "upload": "Upload",
  "emailLabel": "Email",
  "organizationLabel": "Organizations",
  "jobTitle": "Job Title",
  "language": "Language",
  "save": "Save",
  "missingUsernameMsg": "Please input your username!",
  "missingEmailMsg": "Please input your email!",
  "missingFirstnameMsg": "Please input your First Name!",
  "missingLastnameMsg": "Please input your Last Name!",
  "missingJobTitleMsg": "Please input your job Title!",
  "enableTwoFactor": "Enable Two Factor Authentication",
  "towFactor": "Two factor authentication",
  "prosperUs_Url": "ProsperUs_Url",
  "asset": "Asset",
  "country": "country",
  "Fiscal_Year": "Fiscal_Year",
  "fiscal_ID": " Fiscal Id",
  "Financial_Sector": "Financial Sector",
  "organizationModule": "Organization Modules",
  "setOrganization": "Organization Information",
  "setUser": "User information",
  "confirmation": "Confirm",
  "AddUser": "Add User",
  "AddOrganizationUser": "Add Organization User",
  "EditOrganizationUser": "Edit Organization User",
  "Permissions": "Permissions",
  "Contributor": " Contributor",
  "Viewer": "Viewer",
  "None": "None",
  "SelectUserModalText": "Are you sure you want to remove this user",
  "SelectUserModalText2": "All records associated with this user will be lost",
  "missingCompanyNameMsg": "Please input your Organization Name!",
  "missingBankMsg": "Please input your Bank Name!",
  "missingRIBMsg": "Please input your RIB!",
  "missingFullNameMsg": "Please input your Full Name!",
  "IBAN": "IBAN",
  "OrganizationUrl": "Organization Url",
  "OrganizationNameExist": "Organization Name Exist",
  "Next": "Next",
  "isVerifEmail": "The User Email is already used, If you press submit it will be associated to the new organization",
  "isVerifyUserName": "The User Name is already used",
  "Previous": "Previous",
  "Submit": "Submit",
  "OrganizationSignUp": "OrganizationSignUp",
  "SLOGEN": " The Enabler of Digital Transactions.",
  "AlreadyHaveAnAccount": "Already have an account?",
  "No-Authority": "No Authority",

  "NOAUTHORG": "You are not authorized in this page, please contact your organization admin",
  "NoModulePermissions": "Your Account doesn't have the permission to access any module please contact admin",
  "Loading": "Loading",
  "TermsOfUse": "Terms of Use",
  "Compliance": "Compliance",
  "Support": "Support",
  "Contact": "Contact",
  "CopyRightMessage": "Copyright © 2017-2020 Prosperus | Privacy Policy",
  "EmailOrUserName": "Email or UserName",

  "ACCOUNT/PUT_ORGANIZATION_USERS_RESULT_SUCCESS": "The user has being edited successfully",
  "ACCOUNT/PUT_ORGANIZATION_USERS_RESULT_ERROR": "Editing the user has failed",
  "ACCOUNT/POST_ORGANIZATION_USERS_RESULT_SUCCESS": "The user has being added successfully",
  "ACCOUNT/POST_ORGANIZATION_USERS_RESULT_ERROR": "Adding the user has failed",
  "ACCOUNT/login_result_RESULT_ERROR": " Logging has failed",
  "ACCOUNT/login_result_RESULT_SUCCESS": "Logging has being successful",
  "ACCOUNT/update_result_RESULT_SUCCESS": "User information is updated successfully",
  "ACCOUNT/update_result_RESULT_ERROR": "User update has Failed",
  "ACCOUNT/update_organization_result_RESULT_SUCCESS": "Organization information has been updated successfuly",
  "ACCOUNT/update_organization_result_RESULT_ERROR": "Organization information update has failed",
  "BACKOFFICE": "BackOffice",
  "BILLS": "Bills",
  "DASHBOARD": "Dashboard",
  "MARKETPLACE": "MarketPlace",
  "WALLET": "Wallet",
  "PersonalAccount": "Personal Account",
  "Services": "Services",
  "Public_Sector": "Public Sector",
  "NgoNonProfit": "Ngo Non Profit",
  "Agriculture": "Agriculture",
  "Entertainment": "Entertainment",
  "Information-Technologies": "Information Technologies",
  "Consumer Goods": "Consumer Goods",
  "Less-then-5": "Less then 5",
  "Between-5-and-10": "Between 5 and 10",
  "Between-10-and-20": "Between 10 and 20",
  "Between-20-and-50": "Between 20 and 50",
  "Between-50-and-100": "Between 50 and 100",
  "Greater-then-100": "Greater-then-100",
  "defaultUnitRequired": "default Unit Required"
}
