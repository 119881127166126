import { Button, Card, Divider, Steps, Typography } from 'antd'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import { TranslatedMessage } from '../../../shared/translations/data'
import { initialOrganizationSignupVerify } from '../Login'
import OrganizationStep from './organizationSignUpSteps/OrganizationStep'
import UserStep from './organizationSignUpSteps/UserStep'

const { Step } = Steps

const mapStateToProps = ({}: State) => ({})
const mapDispatchToProps = {
  initialOrganizationSignupVerify: initialOrganizationSignupVerify,
}

type OwnProps = { modules: Array<string> }
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps
type Props = StateProps & DispatchProps & OwnProps
const SignUpCard = styled(Card)`
  min-width: 512px;
`
const SignUpHeader = styled(Typography.Title)`
  text-align: center;
  margin-bottom: 24px;
  text-align: 'center';
`

const StepContainer = styled.div``
const OrganizationSignUp = ({
  modules,
  initialOrganizationSignupVerify,
}: Props) => {
  const [current, setCurrent] = React.useState(0)

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
    initialOrganizationSignupVerify()
  }

  return (
    <>
      <SignUpCard bordered={false}>
        <SignUpHeader level={4}>
          <TranslatedMessage id="OrganizationSignUp" />
        </SignUpHeader>
        <StepContainer>
          <Steps current={current} style={{ color: 'green' }}>
            <Step title={<TranslatedMessage id="setOrganization" />}></Step>
            <Step title={<TranslatedMessage id="setUser" />} />
          </Steps>
          <Divider />

          {current === 0 && <OrganizationStep next={next} />}
          {current === 1 && (
            <UserStep next={next} prev={prev} modules={modules} />
          )}
        </StepContainer>
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          <TranslatedMessage id="AlreadyHaveAnAccount" />
          <Button type="link" color={'primary'}>
            <Link to={'/auth/login'}>
              <TranslatedMessage id="login" />
            </Link>
          </Button>
        </Typography.Paragraph>
      </SignUpCard>
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationSignUp)
