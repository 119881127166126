import { LocationChangeAction } from 'connected-react-router'
import { either, option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as t from 'io-ts'
import { withFallback } from 'io-ts-types'
import { optionFromNullable } from 'io-ts-types/lib/optionFromNullable'
import JwtDecode from 'jwt-decode'

import {
  Action,
  actionCreator,
  FailableAction,
  failableActionCreator,
  Loadable,
  PayloadAction,
  payloadActionCreator,
} from '../../../shared/types'
import { FormData } from '../../../shared/types/form'
import { UpdateAccountResult } from '../../Account/types'

const Token = t.type({ authorities: t.array(t.string) })

export const AuthoritiesFromToken = new t.Type<string[], string, unknown>(
  'AuthoritiesFromToken',
  t.array(t.string).is,
  (v, c) =>
    pipe(
      v,
      (v) => t.string.validate(v, c),
      either.map((v) => JwtDecode(v)),
      either.chain((v) => Token.validate(v, c)),
      either.map((t) => t.authorities)
    ),
  (x) => x.join('')
)

export type LoginStatus = '2FA' | { wallets: string[] }

export type State = {
  redirect: option.Option<string>
  account: Loadable<option.Option<UAAAccount & { wallets: string[] }>>
  login: FormData<Login>
  loginResult: Loadable<LoginStatus>
  signup: FormData<SignUp>
  signupResult: Loadable<unknown>
  organizationSignUp: FormData<OrganizationSignUp>
  verifyOrganizationExists: Loadable<unknown>
  verifyUserNameExists: Loadable<unknown>
  verifyEmailExists: Loadable<VerifyEmailExitResult>
  organizationSignupResult: Loadable<unknown>
}

export const AccountOrganizationPermission = t.type({
  DASHBOARD: withFallback(t.string, 'NONE'),
  BACKOFFICE: withFallback(t.string, 'NONE'),
  WALLET: withFallback(t.string, 'NONE'),
  MARKETPLACE: withFallback(t.string, 'NONE'),
  BILLS: withFallback(t.string, 'NONE'),
})
export type AccountOrganizationPermission = t.TypeOf<
  typeof AccountOrganizationPermission
>
export const AccountOrganizationAuthorities = t.keyof({
  /* eslint-disable @typescript-eslint/naming-convention */
  ORGANIZATION_ADMIN: null,
  ORGANIZATION_USER: null,
  /* eslint-enable @typescript-eslint/naming-convention */
})
export type AccountOrganizationAuthorities = t.TypeOf<
  typeof AccountOrganizationAuthorities
>
export const AccountOrganizationInfo = t.type(
  {
    name: t.string,
    permissions: AccountOrganizationPermission,
    authorities: AccountOrganizationAuthorities,
  },
  'AccountOrganizationInfo '
)
export const UAAAccount = t.type(
  {
    id: t.number,
    login: t.string,
    email: optionFromNullable(t.string),
    firstName: optionFromNullable(t.string),
    lastName: optionFromNullable(t.string),
    activated: t.boolean,
    imageUrl: optionFromNullable(t.string),
    authorities: withFallback(t.array(t.string), []),
    langKey: optionFromNullable(t.string),
    tfaEnabled: t.boolean,
    organizations: t.array(AccountOrganizationInfo),
    jobTitle: optionFromNullable(t.string),
  },
  'Account'
)
export type UAAAccount = t.TypeOf<typeof UAAAccount>

export const emptyAccount: Readonly<UAAAccount> = {
  id: 0,
  login: '',
  email: option.none,
  firstName: option.none,
  lastName: option.none,
  jobTitle: option.none,

  activated: false,
  imageUrl: option.none,
  authorities: [],
  langKey: option.none,
  tfaEnabled: false,
  organizations: [],
}

export type Login = {
  username: string
  password: string
  tfacode: string
}

export type SignUp = {
  firstName: string
  email: string
  lastName: string
  login: string
}
export type GoogleLogin = {
  token: string
}
export type OrganizationSignUp = {
  organizationName: string
  email: string
  userName: string
  organizationUrl: string
}
export const VerifyEmailExitResult = t.type(
  {
    email: t.string,
    username: t.string,
  },
  'VerifyEmailExitResult '
)

export type VerifyEmailExitResult = t.TypeOf<typeof VerifyEmailExitResult>
export type OrganizationSignUpWithModules = OrganizationSignUp & {
  modules: Array<string>
}

export type VerifyOrganizationExistsAction = PayloadAction<
  'ACCOUNT/Verify_Organization_Exists',
  string
>
export const verifyOrganizationExistsAction = payloadActionCreator<VerifyOrganizationExistsAction>(
  'ACCOUNT/Verify_Organization_Exists'
)

export type VerifyOrganizationExistsResultAction = FailableAction<
  'ACCOUNT/Verify_Organization_Exists_result',
  unknown
>
export const verifyOrganizationExistsResultAction = failableActionCreator<VerifyOrganizationExistsResultAction>(
  'ACCOUNT/Verify_Organization_Exists_result'
)

export type VerifyUserNameExistsAction = PayloadAction<
  'ACCOUNT/Verify_USER_NAME_Exists',
  string
>
export const verifyUserNameExistsAction = payloadActionCreator<VerifyUserNameExistsAction>(
  'ACCOUNT/Verify_USER_NAME_Exists'
)

export type VerifyUserNameExistsResultAction = FailableAction<
  'ACCOUNT/Verify_USER_NAME_Exists_result',
  unknown
>
export const verifyUserNameExistsResultAction = failableActionCreator<VerifyUserNameExistsResultAction>(
  'ACCOUNT/Verify_USER_NAME_Exists_result'
)

export type InitialOrganizationSignupVerify = Action<'ACCOUNT/Reset_Verify_Organization_Signup'>
export const initialOrganizationSignupVerify = actionCreator<InitialOrganizationSignupVerify>(
  'ACCOUNT/Reset_Verify_Organization_Signup'
)

export type OrganizationSignUpAction = PayloadAction<
  'ACCOUNT/Organization_signUp',
  OrganizationSignUpWithModules
>
export const organizationSignUpAction = payloadActionCreator<OrganizationSignUpAction>(
  'ACCOUNT/Organization_signUp'
)

export type OrganizationSignUpResultAction = FailableAction<
  'ACCOUNT/Organization_signUp_result',
  unknown
>
export const organizationSignUpResultAction = failableActionCreator<OrganizationSignUpResultAction>(
  'ACCOUNT/Organization_signUp_result'
)

export type VerifyEmailExistsAction = PayloadAction<
  'ACCOUNT/Verify_Email_Exists',
  string
>
export const verifyEmailExistsAction = payloadActionCreator<VerifyEmailExistsAction>(
  'ACCOUNT/Verify_Email_Exists'
)

export type VerifyEmailExistsResultAction = FailableAction<
  'ACCOUNT/Verify_Email_Exists_result',
  VerifyEmailExitResult
>
export const verifyEmailExistsResultAction = failableActionCreator<VerifyEmailExistsResultAction>(
  'ACCOUNT/Verify_Email_Exists_result'
)

export type UpdateOrganizationSignUpFormAction = PayloadAction<
  'ACCOUNT/update_organization_signup_form',
  Partial<OrganizationSignUp>
>
export const updateOrganizationSignUpFormAction = payloadActionCreator<UpdateOrganizationSignUpFormAction>(
  'ACCOUNT/update_organization_signup_form'
)

export type UpdateFormAction = PayloadAction<
  'ACCOUNT/update_form',
  Partial<Login>
>
export const updateFormAction = payloadActionCreator<UpdateFormAction>(
  'ACCOUNT/update_form'
)
export type UpdateSignUpFormAction = PayloadAction<
  'ACCOUNT/update_signup_form',
  Partial<SignUp>
>
export const updateSignUpFormAction = payloadActionCreator<UpdateSignUpFormAction>(
  'ACCOUNT/update_signup_form'
)

export type SendgoogleAccessToken = PayloadAction<
  'ACCOUNT/send_googleAccessToken',
  GoogleLogin
>
export const sendgoogleAccessToken = payloadActionCreator<SendgoogleAccessToken>(
  'ACCOUNT/send_googleAccessToken'
)

export type LoginAction = Action<'ACCOUNT/login'>
export const loginAction = actionCreator<LoginAction>('ACCOUNT/login')

export type LoginResultAction = FailableAction<
  'ACCOUNT/login_result',
  LoginStatus
>
export const loginResultAction = failableActionCreator<LoginResultAction>(
  'ACCOUNT/login_result'
)

export type SignupAction = Action<'ACCOUNT/signup'>
export const signupAction = actionCreator<SignupAction>('ACCOUNT/signup')

export type SignupActionResultAction = FailableAction<
  'ACCOUNT/signup_result',
  unknown
>
export const signupActionResultAction = failableActionCreator<SignupActionResultAction>(
  'ACCOUNT/signup_result'
)

export type GetAccountAction = Action<'ACCOUNT/get_account'>
export const getAccountAction = actionCreator<GetAccountAction>(
  'ACCOUNT/get_account'
)

export type GetAccountResultAction = FailableAction<
  'ACCOUNT/get_account_result',
  UAAAccount & { wallets: string[] }
>
export const getAccountResultAction = failableActionCreator<GetAccountResultAction>(
  'ACCOUNT/get_account_result'
)

export type LogoutAction = Action<'ACCOUNT/logout'>
export const logoutAction = actionCreator<LogoutAction>('ACCOUNT/logout')

export type LogoutResultAction = FailableAction<
  'ACCOUNT/logout_result',
  undefined
>
export const logoutResultAction = failableActionCreator<LogoutResultAction>(
  'ACCOUNT/logout_result'
)

export type ResetLogin = Action<'ACCOUNT/reset_login'>
export const resetLogin = actionCreator<ResetLogin>('ACCOUNT/reset_login')
export type ResetSignUp = Action<'ACCOUNT/reset_signup'>
export const resetSignUp = actionCreator<ResetSignUp>('ACCOUNT/reset_signup')
export type OpenSignupResponseModalAction = Action<'ACCOUNT/open_modal'>
export const openSignupResponseModalAction = actionCreator<OpenSignupResponseModalAction>(
  'ACCOUNT/open_modal'
)

export type CloseSignupResponseModalAction = Action<'ACCOUNT/close_modal'>
export const closeSignupResponseModalAction = actionCreator<CloseSignupResponseModalAction>(
  'ACCOUNT/close_modal'
)

export type Actions =
  | ResetLogin
  | LoginAction
  | LoginResultAction
  | GetAccountAction
  | GetAccountResultAction
  | LogoutAction
  | LogoutResultAction
  | UpdateFormAction
  | UpdateAccountResult
  | LocationChangeAction
  | SendgoogleAccessToken
  | UpdateSignUpFormAction
  | ResetSignUp
  | SignupActionResultAction
  | SignupAction
  | UpdateOrganizationSignUpFormAction
  | VerifyOrganizationExistsAction
  | VerifyOrganizationExistsResultAction
  | VerifyEmailExistsAction
  | VerifyEmailExistsResultAction
  | OrganizationSignUpAction
  | OrganizationSignUpResultAction
  | InitialOrganizationSignupVerify
  | VerifyUserNameExistsAction
  | VerifyUserNameExistsResultAction
