import { CaretLeftOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Divider, Form, Input, Row } from 'antd'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { State } from '../../../../shared/state/store'
import { TranslatedMessage } from '../../../../shared/translations/data'
import { loadable, getFormData } from '../../../../shared/types'
import {
  initialOrganizationSignupVerify,
  organizationSignUpAction,
  updateOrganizationSignUpFormAction,
  verifyEmailExistsAction,
  VerifyEmailExitResult,
  verifyUserNameExistsAction,
} from '../../Login/types'

const VerifEmailResultEmpty: VerifyEmailExitResult = {
  email: '',
  username: '',
}

const mapStateToProps = ({
  account: {
    verifyEmailExists,
    organizationSignUp,
    organizationSignupResult,
    verifyUserNameExists,
  },
}: State) => ({
  organizationSignUp: organizationSignUp,
  signUpResult: organizationSignupResult,
  isVerifEmail: pipe(
    verifyEmailExists,
    loadable.caseOf({
      Ok: () => true,
      _: () => false,
      Err: () => false,
    })
  ),
  isVerifyUserName: pipe(
    verifyUserNameExists,
    loadable.caseOf({
      Ok: () => true,
      _: () => false,
      Err: () => false,
    })
  ),
  verifyEmailExists: pipe(
    verifyEmailExists,

    loadable.getOrElse((): VerifyEmailExitResult => VerifEmailResultEmpty)
  ),
  enableNextStep:
    loadable.isNotRequested(verifyEmailExists) ||
    loadable.isNotRequested(verifyEmailExists),

  loading:
    loadable.isLoading(verifyEmailExists) &&
    loadable.isLoading(verifyUserNameExists),
})

const mapDispatchToProps = {
  verifyEmail: verifyEmailExistsAction,
  updateSignUpFormAction: updateOrganizationSignUpFormAction,
  organizationSignUpAction: organizationSignUpAction,
  initialOrganizationSignupVerify: initialOrganizationSignupVerify,
  verifyUserName: verifyUserNameExistsAction,
}
type OwnProps = { next: any; prev: any; modules: Array<string> }
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps
type Props = StateProps & DispatchProps & OwnProps

const UserStep = ({
  verifyEmailExists,
  isVerifyUserName,
  prev,
  loading,
  isVerifEmail,
  organizationSignUp,
  signUpResult,
  modules,
  organizationSignUpAction,
  updateSignUpFormAction,
  verifyEmail,
  verifyUserName,
}: Props) => {
  const [form] = Form.useForm()
  const onFinish = (values: any) => {
    updateSignUpFormAction(values)
    signUp(values)
  }

  const onchangeEmail = () => {
    verifyEmail(form.getFieldValue('email'))
  }
  const onchangeUserName = () => {
    verifyUserName(form.getFieldValue('userName'))
  }
  const signUp = (values: any) => {
    const signupData = {
      ...getFormData(organizationSignUp),
      ...values,
      modules: modules,
    }
    organizationSignUpAction(signupData)
  }
  React.useEffect(() => {
    form.setFieldsValue({
      email: verifyEmailExists.email,
      userName: verifyEmailExists.username,
    })
  }, [form, verifyEmailExists])

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        email: verifyEmailExists.email,
        userName: verifyEmailExists.username,
      }}
    >
      <Form.Item
        label={<FormattedMessage id="emailLabel" defaultMessage="Email" />}
        name="email"
        required
        hasFeedback
        help={
          isVerifEmail ? (
            <FormattedMessage
              id="isVerifEmail"
              defaultMessage=" The User Email is already used, If you press submit it will be associated to the new organization"
            />
          ) : undefined
        }
        validateStatus={isVerifEmail ? 'warning' : undefined}
        rules={[
          {
            required: true,
            type: 'email',
            message: (
              <FormattedMessage
                id="invalidEmail"
                defaultMessage="Invalid e-mail address"
              />
            ),
          },
        ]}
      >
        <Input allowClear type="email" onChange={onchangeEmail} />
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="username" defaultMessage="User name" />}
        name="userName"
        required
        hasFeedback
        help={
          isVerifyUserName ? (
            <FormattedMessage
              id="isVerifyUserName"
              defaultMessage="The User Name is already used"
            />
          ) : undefined
        }
        validateStatus={isVerifyUserName ? 'error' : ''}
        rules={[
          {
            required: true,

            message: (
              <FormattedMessage
                id="missingUsernameMsg"
                defaultMessage="Please input your username!"
              />
            ),
          },
        ]}
      >
        <Input disabled={isVerifEmail} onChange={onchangeUserName} allowClear />
      </Form.Item>
      {isVerifEmail ? (
        <Form.Item>
          <Alert
            style={{ textAlign: 'center' }}
            message={
              <FormattedMessage
                id="isVerifEmail"
                defaultMessage=" The User Email is already used, If you press submit it will be associated to the new organization"
              />
            }
            type="success"
          />
        </Form.Item>
      ) : null}
      {isVerifyUserName ? (
        <Form.Item>
          <Alert
            style={{ textAlign: 'center' }}
            message={
              <FormattedMessage
                id="isVerifyUserName"
                defaultMessage="The User Name is already used"
              />
            }
            type="error"
          />
        </Form.Item>
      ) : null}
      {loadable.isErr(signUpResult) && (
        <Alert
          style={{ textAlign: 'center' }}
          message={<TranslatedMessage id="signupFailed" />}
          type="error"
        />
      )}

      {loadable.isOk(signUpResult) && (
        <Alert
          style={{ textAlign: 'center' }}
          message={<TranslatedMessage id="signupSuccess" />}
          type="success"
        />
      )}
      <Divider />
      <Row justify="end">
        <Col>
          <Button
            icon={<CaretLeftOutlined />}
            style={{ margin: '0 8px' }}
            onClick={() => prev()}
          >
            <FormattedMessage id="Previous" defaultMessage="Previous" />
          </Button>
          <Button
            loading={loading}
            disabled={!form.isFieldTouched('email')}
            type="primary"
            htmlType="submit"
          >
            <FormattedMessage id="Submit" defaultMessage="Submit" />
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(UserStep)
