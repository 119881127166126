import {
  CreditCardOutlined,
  FundFilled,
  SettingFilled,
  ShopOutlined,
  WalletFilled,
} from '@ant-design/icons'
import { Alert, Avatar, Col, Dropdown, Menu, Row } from 'antd'
import { push } from 'connected-react-router'
import { array, option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import * as React from 'react'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import organizationSetting from '../../../assets/organizationSetting.svg'
import {
  applications,
  emptyOrganization,
  getOrganizationUsers,
  Organization,
} from '../../../components/Account'
import {
  emptyAccount,
  UAAAccount,
} from '../../../components/Authentication/Login'
import { loadable } from '../../../shared/types'
import { State } from '../../state/store'
import { TranslatedMessage } from '../../translations/data'

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
const mapDispatchToProps = {
  getOrganizationUsers: getOrganizationUsers,
  navigate: (key: string) =>
    push(key === 'companySettings' ? '/organization_setting' : ''),
}
const mapStateToProps = ({
  account,
  accountSetting: { organization },
}: State) => {
  const a = pipe(
    account.account,
    loadable.toOption,
    option.flatten,
    option.getOrElse((): UAAAccount => emptyAccount),
    UAAAccount.encode
  )
  const currentOrganization = pipe(
    organization,
    loadable.getOrElse((): Organization => emptyOrganization)
  )
  const organizationInfo = a.organizations.find(
    (organization) => organization.name === currentOrganization.name
  )
  const permissions =
    organizationInfo && organizationInfo?.permissions
      ? Object.entries(organizationInfo?.permissions).filter(
          ([_, permission]) => permission !== 'NONE'
        )
      : []
  const permissionsToEdit =
    organizationInfo?.authorities &&
    organizationInfo?.authorities === 'ORGANIZATION_ADMIN'
      ? true
      : false

  return {
    permission: permissionsToEdit,
    applications: pipe(
      applications,
      array.filter((app) =>
        permissions.map(([modules, _]) => modules).includes(app.name)
      )
    ),
  }
}

const images = {
  WALLET: <WalletFilled />,
  BILLS: <CreditCardOutlined />,
  MARKETPLACE: <ShopOutlined />,
  DASHBOARD: <FundFilled />,
  BACKOFFICE: <SettingFilled />,
}

const OrganizationSettingsDropDown = ({
  applications,
  permission,

  navigate,
}: Props) => {
  const SettingsMenu = () => (
    <Menu
      style={{ width: 'auto' }}
      onSelect={({ key }) => navigate(typeof key === 'number' ? `${key}` : key)}
    >
      <Menu.Item key="companySettings">
        <Link to="/organization_setting">
          <TranslatedMessage
            id="companySettings"
            defaultMessage="Organization Settings"
          />
        </Link>
      </Menu.Item>
      <Menu.SubMenu
        title={<TranslatedMessage id="modules" defaultMessage="Modules" />}
        style={{ width: '300px' }}
      >
        {applications.length === 0 ? (
          <Alert
            message={
              <TranslatedMessage
                id={'No-Authority'}
                defaultMessage="No Authority"
              />
            }
            description={
              <TranslatedMessage
                id="NOAUTHORG"
                defaultMessage="You are not authorized in this page, please contact your organization admin"
              />
            }
            type="warning"
          />
        ) : (
          applications.map((app) => (
            <Menu.Item
              key={app.descriptionKey}
              onClick={() => {
                window.location.href = app.href
              }}
            >
              <Row style={{ width: '200px' }}>
                <Col flex={2}>
                  <Avatar icon={images[app.name]} />
                </Col>
                <Col>
                  <a href={app.href} />
                  <TranslatedMessage id={app.name} />
                </Col>
              </Row>
            </Menu.Item>
          ))
        )}
      </Menu.SubMenu>
    </Menu>
  )
  return (
    <>
      {permission && (
        <Dropdown overlay={<SettingsMenu />} placement="topCenter" arrow>
          <Avatar icon={<img src={organizationSetting} />} />
        </Dropdown>
      )}
    </>
  )
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationSettingsDropDown)
