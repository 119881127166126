import { AppstoreOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu, Typography } from 'antd'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  getOrganizationAction,
  getOrganizationUsers,
} from '../../../components/Account'
import { logoutAction } from '../../../components/Authentication/Login'
import { State } from '../../state/store'
import { TranslatedMessage } from '../../translations/data'

type OwnProps = {
  logoutAction: typeof logoutAction
  accountInfo: {
    id: number
    login: string
    email: string | null
    firstName: string | null
    lastName: string | null
    activated: boolean
    imageUrl: string | null
    authorities: string[]
    langKey: string | null
    tfaEnabled: boolean
    organizations: any[]
    jobTitle: string | null
  }
}
const mapStateToProps = ({}: State) => ({})

const mapDispatchToProps = {
  getOrganizationAction: getOrganizationAction,
  getOrganizationUsers: getOrganizationUsers,
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  OwnProps

const MenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  & > span.ant-typography {
    margin-top: 16px;
    line-height: 24px;
    height: 24px;
  }
`

const AccountSettingsDropDown = ({
  accountInfo,
  logoutAction,
  getOrganizationAction,
}: Props) => {
  const hasMultipleOrgs = accountInfo.organizations.length > 1

  const AccountMenu = () => (
    <Menu style={{ padding: '16px' }}>
      <Menu.Item style={{ height: 'auto' }}>
        <MenuHeader>
          <Avatar
            size="large"
            icon={<UserOutlined />}
            src={`/uaa/api/account/picture/${accountInfo.imageUrl}`}
          />
          <Typography.Text type="secondary">
            {accountInfo.login}
          </Typography.Text>
          <Typography.Text type="secondary">
            {accountInfo.email}
          </Typography.Text>
        </MenuHeader>
      </Menu.Item>
      <Menu.Item
        style={{ textAlign: 'center', color: 'green' }}
        key="personalSetting"
      >
        <Link to="/personal_setting">
          <TranslatedMessage
            id="personalSetting"
            defaultMessage="Personal Setting"
          />
        </Link>
      </Menu.Item>
      {accountInfo.organizations.map(
        (organization) =>
          organization.name !== 'PROSPERUS' && (
            <Menu.Item
              key={organization.name}
              icon={<AppstoreOutlined />}
              onClick={() => getOrganizationAction(organization.name)}
            >
              {organization.name} Account
            </Menu.Item>
          )
      )}
      {hasMultipleOrgs &&
        accountInfo.organizations.map(
          (organization) =>
            organization.name === 'PROSPERUS' && (
              <Menu.Item
                key={organization.name}
                icon={<UserOutlined />}
                onClick={() => getOrganizationAction(organization.name)}
              >
                <TranslatedMessage id="PersonalAccount" />
              </Menu.Item>
            )
        )}
      <Menu.Item danger={true} key="logout" onClick={logoutAction}>
        <TranslatedMessage id="logout" defaultMessage="Logout" />
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={<AccountMenu />} placement="topCenter" arrow>
      <Avatar
        icon={<UserOutlined />}
        src={`/uaa/api/account/picture/${accountInfo.imageUrl}`}
      />
    </Dropdown>
  )
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsDropDown)
