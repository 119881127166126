import * as t from 'io-ts'
import { DateString } from './Codec'
import { pipe } from 'fp-ts/lib/pipeable'
import { option, record, io } from 'fp-ts'
import { sequenceT } from 'fp-ts/lib/Apply'
import { Eq } from 'fp-ts/lib/Eq'
import { Loadable, loadable } from './Loadable'
import { constVoid } from 'fp-ts/lib/function'
import { Action } from './Actions'
import { Platform } from '../../shared/state/Info'
import { optionFromNullable } from 'io-ts-types/lib/optionFromNullable'

const phoneRegex = (platform: Platform) =>
  platform === 'sola'
    ? /^(\+|00)[0-9]{1,3}[0-9]{8,9}$/
    : /^(\+|00)[0-9]{1,3}[0-9]{8}$/

export const Phonenumber = (platform: Platform) =>
  new t.Type<string, string, unknown>(
    'Phonenumber',
    (u): u is string => typeof u === 'string',
    (u, c) =>
      typeof u === 'string' && phoneRegex(platform).test(u)
        ? t.success(u)
        : t.failure(u, c),
    t.identity
  )

const cinRegex = (platform: Platform) =>
  platform === 'sola' ? /^[0-9]{9}$/ : /^[0-9]{8}$/

export const CIN = (platform: Platform) =>
  new t.Type<string, string, unknown>(
    'CIN',
    (u): u is string => u instanceof Date,
    (u, c) =>
      typeof u === 'string' && cinRegex(platform).test(u)
        ? t.success(u)
        : t.failure(u, c),
    t.identity
  )

const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const EmailAddress = new t.Type<string, string, unknown>(
  'Email',
  (u): u is string => typeof u === 'string',
  (u, c) =>
    typeof u === 'string' && emailRegex.test(u)
      ? t.success(u)
      : t.failure(u, c),
  t.identity
)

export const Profile = (platform: Platform) =>
  t.type({
    nym: t.string,
    firstName: optionFromNullable(t.string),
    lastName: optionFromNullable(t.string),
    phoneNumber: optionFromNullable(Phonenumber(platform)),
    emailAddress: optionFromNullable(EmailAddress),
    cin: optionFromNullable(CIN(platform)),
    scannedCin: optionFromNullable(t.string),
    facebook: optionFromNullable(t.string),
    gender: optionFromNullable(t.string),
    birthDate: optionFromNullable(t.string),
    governorate: optionFromNullable(t.string),
    cinRecto: optionFromNullable(t.string),
    cinVerso: optionFromNullable(t.string),
    userFace: optionFromNullable(t.string),
    profilePic: optionFromNullable(t.string),
    tradeName: optionFromNullable(t.string),
    crn: optionFromNullable(t.string),
    address: optionFromNullable(t.string),
    zipCode: optionFromNullable(t.string),
    androidVersion: optionFromNullable(t.string),
    operatorName: optionFromNullable(t.string),
    imei: optionFromNullable(t.string),
    deviceID: optionFromNullable(t.string),
    appCategory: optionFromNullable(t.string),
    patente: optionFromNullable(t.string),
    justificatifDeRevenue: optionFromNullable(t.string),
    accountType: optionFromNullable(t.string),
    description: optionFromNullable(t.string),
    createdAt: optionFromNullable(DateString),
  })

export type Profile = t.TypeOf<ReturnType<typeof Profile>>

export const emptyProfile: Profile = {
  nym: '',
  firstName: option.none,
  lastName: option.none,
  phoneNumber: option.none,
  emailAddress: option.none,
  cin: option.none,
  scannedCin: option.none,
  facebook: option.none,
  gender: option.none,
  birthDate: option.none,
  governorate: option.none,
  cinRecto: option.none,
  cinVerso: option.none,
  userFace: option.none,
  profilePic: option.none,
  tradeName: option.none,
  crn: option.none,
  address: option.none,
  zipCode: option.none,
  androidVersion: option.none,
  operatorName: option.none,
  imei: option.none,
  deviceID: option.none,
  appCategory: option.none,
  patente: option.none,
  justificatifDeRevenue: option.none,
  accountType: option.none,
  description: option.none,
  createdAt: option.none,
}

export const performIfNotRequested = (
  p: Loadable<any>,
  a: io.IO<Action<string>>
) =>
  pipe(
    p,
    option.fromPredicate(loadable.isNotRequested),
    option.map(io.of(a)),
    option.option.sequence(io.io),
    io.map(constVoid)
  )

export const eqProfile: Eq<Profile> = {
  equals: (a, b) => a.nym === b.nym,
}

export const contactQR = ({ nym, ...p }: Profile) =>
  // TODO: get the base path from build config
  pipe(
    sequenceT(option.option)(p.firstName, p.lastName),
    option.map(([firstName, lastName]) => `${firstName} ${lastName}`),
    option.alt(() => p.tradeName),
    option.getOrElse(() => 'unknown'),
    (n) => ({ n, nym, v: '1', op: 'id' }),
    record.reduceWithIndex(
      new URL('/dcr/', 'https://dev.tpay.tn'),
      (k, p, c) => {
        p.searchParams.set(k, c)
        return p
      }
    )
  )
