import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd'
import { array, ord, record } from 'fp-ts'
import { pipe } from 'fp-ts/lib/pipeable'
import moment from 'moment'
import * as React from 'react'

import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import { TranslatedMessage } from '../../../shared/translations/data'
import { loadable, memoed } from '../../../shared/types'
import {
  banks,
  countries,
  countryToFlag,
} from '../../../shared/utils/countyUtils'
import {
  emptyOrganization,
  Organization,
  updateOrganizationAction,
} from '../types'

const { Option } = Select

const Save = styled(Button)`
  width: 251px;
  height: 32px;
`
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const mapStateToProps = ({
  accountSetting: { organization },
  units,
}: State) => ({
  units: memoed.extract(units),
  organization: pipe(
    organization,
    loadable.getOrElse((): Organization => emptyOrganization)
  ),
})

const mapDispatchToProps = {
  updateOrganization: updateOrganizationAction,
}
type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
const ordBySnd: ord.Ord<[unknown, string]> = pipe(
  ord.ordString,
  ord.contramap((v) => v[1])
)
const OrganizationInformation = ({
  organization,
  units,
  updateOrganization,
}: Props) => {
  const [form] = Form.useForm()
  React.useEffect(() => form.resetFields(), [organization])
  return (
    <>
      <Form
        form={form}
        initialValues={{
          id: organization.id,
          bankName: organization.bankName,
          url: `${organization.name}.tech`,
          iban: organization.iban,
          fiscalYear: moment(organization.fiscalYear, 'YYYY'),
          fiscalId: organization.fiscalId,
          name: organization.name,
          rib: organization.rib,
          employeesNumber: organization.employeesNumber,
          activity: organization.activity,
          country: organization.country,
          asset: organization.asset,
        }}
        {...formItemLayout}
        onFinish={(values) => {
          values.fiscalYear = values.fiscalYear.year()

          updateOrganization({ ...values, id: organization.id })
        }}
      >
        <Row>
          <Col span={10}>
            <Form.Item
              label={<TranslatedMessage id={'organizationName'} />}
              name="name"
              rules={[
                {
                  required: true,
                  message: (
                    <TranslatedMessage
                      id="missingCompanyNameMsg"
                      defaultMessage="Please input your company Name!"
                    />
                  ),
                },
              ]}
            >
              <Input placeholder={'Organization Name'} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label={<TranslatedMessage id={'prosperUs_Url'} />}
              name="url"
            >
              <Input disabled={true} placeholder={'ProsperUs_Url'} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item
              label={<TranslatedMessage id="fiscal_ID" />}
              name="fiscalId"
            >
              <Input placeholder={'Fiscal ID'} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label={<TranslatedMessage id="Fiscal_Year" />}
              name="fiscalYear"
            >
              <DatePicker
                style={{ width: '100%' }}
                mode="year"
                picker="year"
                format="YYYY"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item
              label="RIB"
              name="rib"
              rules={[
                {
                  required: true,
                  message: (
                    <TranslatedMessage
                      id="missingRIBMsg"
                      defaultMessage="Please input your RIB!"
                    />
                  ),
                },
              ]}
            >
              <Input type="number" placeholder="RIB" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label={<TranslatedMessage id={'bank'} />}
              name="bankName"
              rules={[
                {
                  required: true,
                  message: (
                    <TranslatedMessage
                      id="missingBankMsg"
                      defaultMessage="Please input your Bank Name!"
                    />
                  ),
                },
              ]}
            >
              <AutoComplete placeholder="Chose your Bank">
                {banks
                  .sort((a: any, b: any) => a.label - b.label)
                  .map(({ code, label }) => (
                    <Option key={label} value={label}>
                      <Space>
                        {label} {code ? `(${code})` : null}
                      </Space>
                    </Option>
                  ))}
              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item
              label={<TranslatedMessage id="IBAN" defaultMessage="IBAN" />}
              name="iban"
            >
              <Input placeholder="IBAN" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label={<TranslatedMessage id={'organizationEmployees'} />}
              name="employeesNumber"
            >
              <Select
                placeholder={<TranslatedMessage id={'employees'} />}
                allowClear
              >
                <Option value={'Less then 5'}>
                  {<TranslatedMessage id={'Less-then-5'} />}
                </Option>
                <Option value={'Between 5 and 10'}>
                  {<TranslatedMessage id={'Between-5-and-10'} />}
                </Option>
                <Option value={'Between 10 and 20'}>
                  {<TranslatedMessage id={'Between-10-and-20'} />}
                </Option>
                <Option value={'Between 20 and 50'}>
                  {<TranslatedMessage id={'Between-20-and-50'} />}
                </Option>
                <Option value={'Between 50 and 100'}>
                  {<TranslatedMessage id={'Between-50-and-100'} />}
                </Option>
                <Option value={'Greater then 100'}>
                  {<TranslatedMessage id={'Greater-then-100'} />}
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item
              label={<TranslatedMessage id={'activity'} />}
              name="activity"
            >
              <Select
                placeholder={<TranslatedMessage id={'activity'} />}
                allowClear
              >
                <Option value="Financial Sector">
                  {<TranslatedMessage id={'Financial_Sector'} />}
                </Option>
                <Option value="Services">
                  {<TranslatedMessage id={'Services'} />}
                </Option>
                <Option value="Public Sector">
                  {<TranslatedMessage id={'Public_Sector'} />}
                </Option>
                <Option value="NonProfit">
                  {<TranslatedMessage id={'NgoNonProfit'} />}
                </Option>
                <Option value="Agriculture">
                  {<TranslatedMessage id={'Agriculture'} />}
                </Option>
                <Option value="Entertainment">
                  {<TranslatedMessage id={'Entertainment'} />}
                </Option>
                <Option value="Information Technology">
                  {<TranslatedMessage id={'Information-Technologies'} />}
                </Option>
                <Option value="Consumer Goods">
                  {<TranslatedMessage id={'Consumer Goods'} />}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label={<TranslatedMessage id={'country'} />}
              name="country"
            >
              <AutoComplete placeholder="Country">
                {countries.map(({ code, label, phone }) => (
                  <Option key={code} value={label}>
                    <Space>
                      <span>{countryToFlag(code)}</span>
                      {label} ({code}) +{phone}
                    </Space>
                  </Option>
                ))}
              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item
              name="asset"
              label={<TranslatedMessage id="asset" />}
              rules={[
                {
                  // required: true,
                  message: <TranslatedMessage id={'defaultUnitRequired'} />,
                },
              ]}
            >
              <Select>
                {pipe(
                  units,
                  record.map((u) => `${u.name} ${u.symbol}`),
                  record.toArray,
                  array.sort(ordBySnd),
                  array.map((v) => (
                    <Select.Option value={v[0]} key={v[0]}>
                      {v[1]}
                    </Select.Option>
                  ))
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[8, 8]} justify="end">
          <Col>
            <Save htmlType="submit" type="primary">
              <TranslatedMessage id="save" defaultMessage="Save" />
            </Save>
          </Col>
        </Row>
      </Form>
    </>
  )
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationInformation)
