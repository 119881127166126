import { Button, Card, Typography } from 'antd'
import * as React from 'react'
import { Route, Switch } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { TranslatedMessage } from '../../../shared/translations/data'
import Finish from './Finish'
import Init from './Init'
import SetPassword from './setPassword'

const ResetPasswordCard = styled(Card)`
  min-width: 512px;
  max-width: 512px;
`
const StepContainer = styled.div`
  width: 200%;

  & > * {
    width: 50%;
    padding: 0 4px;
  }

  margin-bottom: 24px;
`

const ResetPassword = () => (
  <>
    <ResetPasswordCard bordered={false}>
      <StepContainer>
        <Switch>
          <Route exact={true} path="/auth/reset/init" component={Init} />
          <Route exact={true} path="/auth/reset/finish" component={Finish} />
          <Route exact={true} path="/auth/reset/set" component={SetPassword} />
        </Switch>
      </StepContainer>
      <Typography.Paragraph style={{ textAlign: 'center' }}>
        <TranslatedMessage id="createAccountQuestion" />
        <Button type="link" color={'primary'}>
          <Link to={'/auth/signup/email'}>
            <TranslatedMessage id="signUp" />
          </Link>
        </Button>
      </Typography.Paragraph>
    </ResetPasswordCard>
  </>
)
export default ResetPassword
